import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export abstract class DestroyedService implements OnDestroy {

  protected destroyed: Subject<void> = new Subject<void>();

  protected constructor() {
    this.destroyed = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
