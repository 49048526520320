import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core'
import {takeUntil} from 'rxjs'
import {AuthenticationService} from 'src/app/core/services/commons/authentication.service'
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service'
import {CoachingDate} from '../../models/coaching-date.model'
import {ListCoachingDateDTO} from '../../models/dto/DTO.models'
import {CoachingStatusEnum, RoleEnum} from '../../models/enums/Enums.enum'
import {Utilisateur} from '../../models/utilisateur.models'

@Component({
  selector: 'app-card-coaching-date',
  templateUrl: './card-coaching-date.component.html',
  styleUrls: ['./card-coaching-date.component.scss'],
})
export class CardCoachingDateComponent extends DestroyedService
  implements OnInit {
  @Input() coaching: ListCoachingDateDTO

  @Input() isFirstElement: boolean = false

  @Input() isPatadome: boolean = false

  @Output() selectedDate: EventEmitter<CoachingDate> = new EventEmitter()

  statutEnum = CoachingStatusEnum

  me: Utilisateur
  hasGoodRole: boolean = false

  readonly CoachingStatusEnum = CoachingStatusEnum;

  constructor(private authService: AuthenticationService) {
    super()
  }

  ngOnInit(): void {
    this.authService.currentUser.pipe(takeUntil(this.destroyed)).subscribe({
      next: (res) => {
        this.me = res
        if (
          this.me.role == RoleEnum[RoleEnum.PATADOME].toString() ||
          this.me.role == RoleEnum[RoleEnum.COACH].toString()
        ) {
          this.hasGoodRole = true
        }
      },
    })
  }

  selectHoraire(coachingDate: CoachingDate) {
    this.selectedDate.emit(coachingDate)
  }

  getStatutHumanize(coachStatusEnum: CoachingStatusEnum): string {
    if (
      CoachingStatusEnum[coachStatusEnum] ==
      CoachingStatusEnum[CoachingStatusEnum.VALIDE]
    ) {
      return 'confirmé'
    } else if (
      CoachingStatusEnum[coachStatusEnum] ==
      CoachingStatusEnum[CoachingStatusEnum.HONORE]
    ) {
      return 'honoré (a bien eu lieu)'
    } else if (
      CoachingStatusEnum[coachStatusEnum] ==
      CoachingStatusEnum[CoachingStatusEnum.NON_HONORE]
    ) {
      return 'non honoré (n\'a pas eu lieu)'
    }
    return ''
  }
}
