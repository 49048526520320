import {CommonModule} from '@angular/common'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatNativeDateModule} from '@angular/material/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'

import {BLOB_STORAGE_TOKEN, IAzureStorage} from 'src/app/core/azure-storage/azureStorage'
import {BlobStorageService} from 'src/app/core/azure-storage/blob-storage.service'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {ErrorInterceptor} from './core/interceptors/error.interceptor'
import {JwtInterceptor} from './core/interceptors/jwt.interceptor'
import {SharedModule} from './shared/shared.module'

declare const AzureStorage: IAzureStorage

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    BlobStorageService,
    {
      provide: BLOB_STORAGE_TOKEN,
      useValue: AzureStorage.Blob,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
