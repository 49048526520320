import {Component, Inject} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs';

import {UtilisateurService} from 'src/app/core/services/webservices/utilisateur.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Utilisateur} from 'src/app/shared/models/utilisateur.models';

@Component({
  selector: 'app-ajouter-collaborateur',
  templateUrl: './ajouter-collaborateur.component.html',
  styleUrls: ['./ajouter-collaborateur.component.scss']
})
export class AjouterCollaborateurComponent extends DestroyedService {

  control: FormGroup;
  nom: AbstractControl;
  prenom: AbstractControl;
  fonction: AbstractControl;
  mail: AbstractControl;
  role: AbstractControl;
  sexe: AbstractControl;
  company: AbstractControl;
  cp: AbstractControl;
  city: AbstractControl;
  trancheAge: AbstractControl;

  constructor(
    public dialogRef: MatDialogRef<AjouterCollaborateurComponent>,
    private serviceUtilisateur: UtilisateurService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { idClient: number; role: string },
    fb: FormBuilder,
  ) {
    super();
    // Init form
    this.control = fb.group({
      nom: ['', Validators.compose([Validators.required])],
      prenom: ['', Validators.compose([Validators.required])],
      fonction: ['', Validators.compose([Validators.required])],
      mail: ['', Validators.compose([Validators.required, Validators.email])],
      sexe: ['', Validators.compose([Validators.required])],
      role: ['', Validators.compose([Validators.required])],
      company: [''],
      cp: [''],
      city: [''],
      trancheAge: [''],
    });
    this.nom = this.control.controls['nom'];
    this.prenom = this.control.controls['prenom'];
    this.fonction = this.control.controls['fonction'];
    this.mail = this.control.controls['mail'];
    this.role = this.control.controls['role'];
    this.sexe = this.control.controls['sexe'];
    this.company = this.control.controls['company'];
    this.cp = this.control.controls['cp'];
    this.city = this.control.controls['city'];
    this.trancheAge = this.control.controls['trancheAge'];
  }

  onSubmit() {
    if (this.control.valid) {
      const utilisateur = {} as Utilisateur;
      utilisateur.idClient = this.data.idClient;
      utilisateur.nom =
        this.nom.value.charAt(0).toLowerCase() +
        this.nom.value.substring(1).toLowerCase();
      utilisateur.prenom =
        this.prenom.value.charAt(0).toLowerCase() +
        this.prenom.value.substring(1).toLowerCase();
      utilisateur.mail = this.mail.value;
      utilisateur.fonction =
        this.fonction.value.charAt(0).toLowerCase() +
        this.fonction.value.substring(1).toLowerCase();
      utilisateur.role = this.role.value;
      utilisateur.sexe = this.sexe.value;
      utilisateur.societe = this.company.value;
      utilisateur.codePostal = this.cp.value;
      utilisateur.ville = this.city.value;
      utilisateur.trancheAge = this.trancheAge.value;
      this.serviceUtilisateur.postUtilisateur(utilisateur).pipe(
        takeUntil(this.destroyed)
      )
        .subscribe((res) => {
          this.dialogRef.close(res)
          this.snackBar.open('L\'utilisateur a bien été ajouté !')
        });
    } else {
      this.snackBar.open(
        'Il y a une erreur dans le formulaire, verifiez que tous les champs soient correctes.',
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
