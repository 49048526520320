import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs';

import {ProduitService} from 'src/app/core/services/webservices/produit.service';
import {ClientService} from 'src/app/core/services/webservices/client.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Produit} from 'src/app/shared/models/produits.models';
import {Client} from 'src/app/shared/models/client.models';
import {ClientDTO, LicenceRequestDTO} from 'src/app/shared/models/dto/DTO.models';

@Component({
  selector: 'app-ajouter-client',
  templateUrl: './ajouter-client.component.html',
  styleUrls: ['./ajouter-client.component.scss']
})
export class AjouterClientComponent extends DestroyedService implements OnInit {

  produits: Array<Produit> = []
  control: FormGroup
  nom: AbstractControl
  adresse: AbstractControl
  ville: AbstractControl
  codePostal: AbstractControl

  constructor(
    public dialogRef: MatDialogRef<AjouterClientComponent>,
    private serviceProduit: ProduitService,
    private serviceClient: ClientService,
    private snackbar: MatSnackBar,
    fb: FormBuilder,
  ) {
    super();
    // Init form
    this.control = fb.group({
      nom: ['', Validators.compose([Validators.required])],
      adresse: ['', Validators.compose([Validators.required])],
      ville: ['', Validators.compose([Validators.required])],
      codePostal: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.pattern('[0-9].*'),
        ]),
      ],
    });
    this.nom = this.control.controls['nom'];
    this.adresse = this.control.controls['adresse'];
    this.ville = this.control.controls['ville'];
    this.codePostal = this.control.controls['codePostal'];
  }

  ngOnInit() {
    this.serviceProduit.getProduit().pipe(takeUntil(this.destroyed)).subscribe((res) => {
      for (let result of res) {
        this.produits.push(result)
      }
    });
  }

  onSubmit() {
    if (this.control.valid) {
      const clientDTO = {} as ClientDTO;
      const listLicenceRequestDTO: Array<LicenceRequestDTO> = [];
      const client = {} as Client;
      client.id = 0;
      client.nom =
        this.nom.value.charAt(0).toUpperCase() +
        this.nom.value.substring(1).toLowerCase();
      client.adresse =
        this.adresse.value.charAt(0).toUpperCase() +
        this.adresse.value.substring(1).toLowerCase();
      client.ville =
        this.ville.value.charAt(0).toUpperCase() +
        this.ville.value.substring(1).toLowerCase();
      client.codePostal = this.codePostal.value;
      client.nombreApprenantCible = 0;
      client.actif = true;
      client.listOfUtilisateur = undefined;
      for (let produit of this.produits) {
        listLicenceRequestDTO.push({
          IdProduit: produit.id,
          Nombre: produit.numberOfLicence,
        });
      }
      clientDTO.Client = client;
      clientDTO.ListLicenceRequestDTO = listLicenceRequestDTO;
      this.serviceClient.postClient(clientDTO).pipe(takeUntil(this.destroyed)).subscribe((res) => {
        this.dialogRef.close(res);
        this.snackbar.open('Le client a bien été créé !');
      });
    } else {
      this.snackbar.open(
        'Il y a une erreur dans le formulaire, verifiez que tous les champs soient correctes.',
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
