<div (click)="emitModuleSelected()"
     [class.disabled]="!isEnabled"
     className="lesson-block {{ getColorClassFromModuleColor(module.color, true, true) }}">
  <div className="lesson-logo-container {{ getColorClassFromModuleColor(module.color, true) }}">
    <img alt="Logo de la leçon" class="lesson-logo" src="{{ module.icon }}">
  </div>
  <div class="lesson-info">
    <div class="lesson-line">
      <span class="lesson-title {{ getColorClassFromModuleColor(module.color, false) }}">{{ module.title }}</span>
    </div>
  </div>
</div>
