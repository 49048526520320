import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {catchError, delay, switchMap, take, throwError, timer} from 'rxjs'
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service'
import {CoachingDateService} from 'src/app/core/services/webservices/coaching-date.service'
import {CoachingDate} from '../../models/coaching-date.model'
import {ResponseCheckRightCoachingDTO} from '../../models/dto/DTO.models'
import {Utilisateur} from '../../models/utilisateur.models'

@Component({
  selector: 'app-coaching-apprenant-rdv',
  templateUrl: './coaching-apprenant-rdv.component.html',
  styleUrls: ['./coaching-apprenant-rdv.component.scss'],
})
export class CoachingApprenantRdvComponent extends DestroyedService
  implements OnInit {
  isCheckOk: boolean = false
  isWaiting: boolean = true
  inProgress: boolean = false
  reason: string = null

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      coach: Utilisateur
      coachingDate: CoachingDate
      me: Utilisateur
    },
    public dialogRef: MatDialogRef<CoachingApprenantRdvComponent>,
    private coachingDateService: CoachingDateService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.isWaiting = true
    this.coachingDateService
      .checkRightCoaching(
        this.data.coach.id,
        this.data.coachingDate.id,
        this.data.me.id,
      )
      .pipe(
        delay(2500),
        catchError(async (err) =>
          timer(2500).pipe(switchMap(() => throwError(() => err))),
        ),
      )
      .subscribe({
        next: (res: ResponseCheckRightCoachingDTO) => {
          this.isWaiting = false
          this.isCheckOk = res.isOk
          this.reason = res.reason
        },
        error: () => {
          this.isWaiting = false
          this.isCheckOk = false
          this.reason =
            'Un problème est survenu lors de la vérification. Veuillez réessayer plus tard !'
        },
      })
  }

  validate() {
    this.inProgress = true;
    this.coachingDateService
      .priseRdvApprenant(
        this.data.coach.id,
        this.data.coachingDate.id,
        this.data.me.id,
      )
      .pipe(take(1))
      .subscribe((res) => {
        this.dialogRef.close({action: 'update', data: res})
        this.inProgress = false;
      })
  }

  close() {
    this.dialogRef.close({action: 'close'})
  }
}
