<div (click)="openCaptationDialog()"
     className="captation-block {{ getColorClassFromModuleColor(module.color, true, true) }}">
  <div className="lesson-logo-container {{ getColorClassFromModuleColor(module.color, true) }}">
    <img alt="Logo de la leçon" class="lesson-logo" src="{{ module.icon }}">
  </div>
  <div class="captation-info">
    <div class="captation-line">
      <span
        class="captation-title {{ getColorClassFromModuleColor(module.color, false) }}">{{ getCaptationDate() }}</span>
    </div>
    <div class="captation-line">
      <div class="captation-label-action">
        <img alt="Logo de visualisation du cours" class="captation-label-action-icon"
             src="/assets/Icon/Item_Description_Icon.png">
        <span class="captation-label-action-span">{{ description.toUpperCase() }}</span>
      </div>
    </div>
  </div>
</div>

