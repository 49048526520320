import {Component, Input, OnInit, ViewChild } from '@angular/core'
import {takeUntil} from 'rxjs'
import {AuthenticationService} from 'src/app/core/services/commons/authentication.service'
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service'
import {RoleEnum} from '../../models/enums/Enums.enum'
import {Utilisateur} from '../../models/utilisateur.models'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CoachingDate } from '../../models/coaching-date.model';

@Component({
  selector: 'app-table-coaching-date-reserved',
  templateUrl: './table-coaching-date-reserved.component.html',
  styleUrls: ['./table-coaching-date-reserved.component.scss'],
})
export class TableCoachingDateReservedComponent extends DestroyedService
  implements OnInit {

  displayedColumns: string[] = ["nom", 'date', 'lien'];
  dataSource:MatTableDataSource<CoachingDate> = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input()
  public set coachingDateReserved(coachingDate: CoachingDate[]) {
    this.dataSource.data = coachingDate;
  }

  me: Utilisateur
  hasGoodRole: boolean = false

  constructor(private authService: AuthenticationService) {
    super()
  }

  ngOnInit(): void {
    this.authService.currentUser.pipe(takeUntil(this.destroyed)).subscribe({
      next: (res) => {
        this.me = res
        if (
          this.me.role == RoleEnum[RoleEnum.PATADOME].toString() ||
          this.me.role == RoleEnum[RoleEnum.COACH].toString()
        ) {
          this.hasGoodRole = true
        }
      },
    })
  }
}
