import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'

import {MatSnackBar} from '@angular/material/snack-bar'
import {Router} from '@angular/router'
import {environment} from 'src/environments/environment'
import {AuthenticationService} from '../services/commons/authentication.service'

@Injectable({providedIn: 'root'})
export class ErrorInterceptor implements HttpInterceptor {
  private readonly LOCAL_STORAGE_TOKEN: string = environment.localStorageToken

  constructor(
    private snackbar: MatSnackBar,
    private router: Router
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(({error, status}: HttpErrorResponse) => {
        if (!AuthenticationService.VerifyToken()) {
          localStorage.removeItem(this.LOCAL_STORAGE_TOKEN)
          this.router.navigate(['/login'])
        } else if (status == 401) {
          localStorage.removeItem(this.LOCAL_STORAGE_TOKEN)
          this.router.navigate(['/login'])
        }
        if (typeof error === 'string') {
          this.snackbar.open('Erreur : ' + error)
        }
        return throwError(() => error)
      }),
    )
  }
}
