<h1 mat-dialog-title>Modification collaborateur</h1>
<div [formGroup]="control" class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col button-left">
      <button
        (click)="mdpOublieCollaborateur()"
        color="primary"
        mat-raised-button
        matTooltip="Mot de passe oublié ?"
      >
        <mat-icon>lock_reset</mat-icon>
        <span>Mot de passe oublié</span>
      </button>
    </div>

    <div class="col button-middle">
      <button
        (click)="reverseActiveCollaborateur()"
        *ngIf="data.collaborateur.actif == true"
        color="primary"
        mat-raised-button
        matTooltip="Désactiver"
      >
        <mat-icon>lock</mat-icon>
        <span>Désactiver</span>
      </button>
      <button
        (click)="reverseActiveCollaborateur()"
        *ngIf="data.collaborateur.actif == false"
        color="primary"
        mat-raised-button
        matTooltip="Activer"
      >
        <mat-icon>lock_open</mat-icon>
        <span>Activer</span>
      </button>
    </div>

    <div class="col button-right">
      <button
        (click)="deleteCollaborateur()"
        color="warn"
        mat-raised-button
        matTooltip="Supprimer le collaborateur ?"
      >
        <mat-icon>delete_forever</mat-icon>
        <span>Supprimer</span>
      </button>
    </div>
  </div>

  <div class="row" style="margin-top: 1rem">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nom</mat-label>
        <input formControlName="nom" matInput/>
        <mat-error *ngIf="nom?.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Prénom</mat-label>
        <input formControlName="prenom" matInput/>
        <mat-error *ngIf="prenom?.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Fonction / Groupe</mat-label>
        <input formControlName="fonction" matInput/>
        <mat-error *ngIf="fonction?.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Sexe</mat-label>
        <mat-select (value)="(sexe)" formControlName="sexe">
          <mat-option value="HOMME"> Homme</mat-option>
          <mat-option value="FEMME"> Femme</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Adresse mail</mat-label>
        <input formControlName="mail" matInput/>
        <mat-error *ngIf="mail?.invalid">Format invalide</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="data.role != 'APPRENANT'" class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Rôle</mat-label>
        <mat-select formControlName="role">
          <mat-option *ngIf="data.role == 'PATADOME'" value="PATADOME">
            Patadome
          </mat-option>
          <mat-option value="ADMINISTRATEUR_CLIENT">
            Administrateur client
          </mat-option>
          <mat-option value="APPRENANT"> Apprenant</mat-option>
          <mat-option *ngIf="data.role == 'PATADOME'" value="COACH">
            Coach
          </mat-option>
        </mat-select>
        <mat-error *ngIf="role?.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Entreprise</mat-label>
        <input formControlName="company" matInput/>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Le code postal de l'entreprise</mat-label>
        <input formControlName="cp" matInput/>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>La ville de l'entreprise</mat-label>
        <input formControlName="city" matInput/>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Tranche d'âge</mat-label>
        <mat-select
          formControlName="trancheAge"
        >
          <mat-option value="moins de 25 ans">moins de 25 ans</mat-option>
          <mat-option value="25 - 30 ans">25 - 30 ans</mat-option>
          <mat-option value="31 - 35 ans">31 - 35 ans</mat-option>
          <mat-option value="36 - 40 ans">36 - 40 ans</mat-option>
          <mat-option value="41 - 45 ans">41 - 45 ans</mat-option>
          <mat-option value="46 - 50 ans">46 - 50 ans</mat-option>
          <mat-option value="51 - 55 ans">51 - 55 ans</mat-option>
          <mat-option value="56 - 60 ans">56 - 60 ans</mat-option>
          <mat-option value="61 - 65 ans">61 - 65 ans</mat-option>
          <mat-option value="plus de 65 ans">plus de 65 ans</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Coach</mat-label>
        <input
          *ngIf="currentUser.role != 'PATADOME'"
          [readonly]="currentUser.role != 'PATADOME'"
          formControlName="coach"
          matInput
        />
        <mat-select
          *ngIf="currentUser.role == 'PATADOME'"
          formControlName="coach"
        >
          <mat-option>None</mat-option>
          <mat-option *ngFor="let coach of coachs" [value]="coach.id">
            {{ coach.prenom }} {{ coach.nom }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="data.collaborateur.licence" class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Fin licence</mat-label>
        <input
          [matDatepicker]="picker"
          [readonly]="currentUser.role != 'PATADOME'"
          formControlName="dateFinLicence"
          matInput
        />
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-checkbox formControlName="isTesteur">Est-il utilisateur testeur (désactive le controle de la licence pour
        l'accès à la formation)
      </mat-checkbox>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button
    (click)="onSubmit()"
    cdkFocusInitial
    color="primary"
    mat-raised-button
  >
    Enregistrer
  </button>
  <button (click)="onCancel()" color="default" mat-raised-button>
    Annuler
  </button>
</div>
