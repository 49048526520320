import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {Licence} from 'src/app/shared/models/licence.models';

@Injectable({
  providedIn: 'root'
})
export class LicenceService {

  constructor(
    private http: HttpClient
  ) {
  }

  getLicence(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Licence`);
  }

  getLicenceFiltred(pFilter: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Licence/filter/${pFilter}`);
  }

  postAssignLicence(idProduit: number, idUtilisateur: number, idClient: number): Observable<any> {
    return this.http.post<Licence>(`${environment.apiUrl}/Licence/assign`, {idProduit, idUtilisateur, idClient});
  }

  putLicence(Id: number, Nombre: number): Observable<any> {
    return this.http.put(`${environment.apiUrl}/Licence`, {Id, IdProduit: null, Nombre});
  }
}
