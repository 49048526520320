import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs';

import {ClientService} from 'src/app/core/services/webservices/client.service';
import {LicenceService} from 'src/app/core/services/webservices/licence.service';
import {ProduitService} from 'src/app/core/services/webservices/produit.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Licence} from 'src/app/shared/models/licence.models';
import {Produit} from 'src/app/shared/models/produits.models';
import {Utilisateur} from 'src/app/shared/models/utilisateur.models';

@Component({
  selector: 'app-affecter-licence',
  templateUrl: './affecter-licence.component.html',
  styleUrls: ['./affecter-licence.component.scss']
})
export class AffecterLicenceComponent extends DestroyedService implements OnInit {

  produits: Array<Produit> = [];
  utilisateurs: Array<Utilisateur> = [];
  value: number | undefined;
  idProduit: number | undefined;
  idApprenant: number | undefined;
  idClient: number | undefined;

  constructor(
    public dialogRef: MatDialogRef<AffecterLicenceComponent>,
    private serviceProduit: ProduitService,
    private serviceLicence: LicenceService,
    private serviceClient: ClientService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { id: number, collaborateur: Utilisateur, licence: Licence }
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.collaborateur != null) {
      this.serviceProduit.getProduitByClient(this.data.id).subscribe(res => {
        for (let resultat of res) {
          this.produits.push(resultat);
        }
      });
      this.idClient = this.data.id;
      this.idApprenant = this.data.collaborateur.id;
    }
    if (this.data.licence != null) {
      this.serviceClient.getClientById(this.data.licence.idClient).pipe(
        takeUntil(this.destroyed)
      )
        .subscribe(res => {
          for (let result of res.listOfUtilisateur) {
            if (result.licence == null || !result.licence.actif) {
              this.utilisateurs.push(result);
            }
          }
        });
      this.idClient = this.data.licence.idClient;
      this.idProduit = this.data.licence.idProduit;
    }
  }

  onSubmit() {
    if (this.data.collaborateur != null)
      this.idProduit = this.value;
    if (this.data.licence != null)
      this.idApprenant = this.value;
    if (this.idProduit && this.idApprenant && this.idClient)
      this.serviceLicence.postAssignLicence(this.idProduit, this.idApprenant, this.idClient).pipe(
        takeUntil(this.destroyed)
      )
        .subscribe((res) => {
          this.dialogRef.close(res);
          this.snackBar.open("La licence a bien été affectée !");
        });
    else if (!this.idProduit)
      this.snackBar.open('Aucun produit n\'est présent en paramètre');
    else if (!this.idApprenant)
      this.snackBar.open('Aucun apprenant n\'est sélectionné');
    else if (!this.idClient)
      this.snackBar.open('Aucun client n\'est sélectionné');
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
