// ANGULAR
import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {RouterModule} from '@angular/router'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap'
// MATERIAL
import {CdkAccordionModule} from '@angular/cdk/accordion'
import {MatButtonModule} from '@angular/material/button'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MAT_DATE_LOCALE} from '@angular/material/core'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatDialogModule} from '@angular/material/dialog'
import {MatExpansionModule} from '@angular/material/expansion'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatGridListModule} from '@angular/material/grid-list'
import {MatIconModule} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio'
import {MatSelectModule} from '@angular/material/select'
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar'
import {MatTabsModule} from '@angular/material/tabs'
// CUSTOM
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {BandeauBasComponent} from './components/bandeau-bas/bandeau-bas.component'
import {BandeauComponent} from './components/bandeau/bandeau.component'
import {BottomSheetComponent} from './components/bottom-sheet/bottom-sheet.component'
import {CardCaptationComponent} from './components/card-captation/card-captation.component'
import {CardCoachingDateComponent} from './components/card-coaching-date/card-coaching-date.component'
import {CardModuleMediathequeComponent} from './components/card-module-mediatheque/card-module-mediatheque.component'
import {CardModuleComponent} from './components/card-module/card-module.component'
import {CustomCheckboxComponent} from './components/custom-checkbox/custom-checkbox.component'
import {FooterComponent} from './components/footer/footer.component'
import {NavbarComponent} from './components/navbar/navbar.component'
import {AffecterCoachComponent} from './dialogs/affecter-coach/affecter-coach.component'
import {AffecterLicenceComponent} from './dialogs/affecter-licence/affecter-licence.component'
import {AfficherBilanComponent} from './dialogs/afficher-bilan/afficher-bilan.component'
import {AjouterClientComponent} from './dialogs/ajouter-client/ajouter-client.component'
import {AjouterCollaborateurComponent} from './dialogs/ajouter-collaborateur/ajouter-collaborateur.component'
import {AjouterCommentaireComponent} from './dialogs/ajouter-commentaire/ajouter-commentaire.component'
import {AjouterLicenceComponent} from './dialogs/ajouter-licence/ajouter-licence.component'
import {AjouterProduitComponent} from './dialogs/ajouter-produit/ajouter-produit.component'
import {AjouterTempsComponent} from './dialogs/ajouter-temps/ajouter-temps.component'
import {CaptationViewerComponent} from './dialogs/captation-viewer/captation-viewer.component'
import {CoachingApprenantRdvComponent} from './dialogs/coaching-apprenant-rdv/coaching-apprenant-rdv.component'
import {CoachingDateEditComponent} from './dialogs/coaching-date-edit/coaching-date-edit.component'
import {ConfirmationComponent} from './dialogs/confirmation/confirmation.component'
import {ModifierClientComponent} from './dialogs/modifier-client/modifier-client.component'
import {ModifierCollaborateurComponent} from './dialogs/modifier-collaborateur/modifier-collaborateur.component'
import {FirstLetterPipe} from './pipes/first-letter.pipe'
import {MatCardModule} from '@angular/material/card'
import {SnackBarWithActionsComponent} from './components/snackbar-with-actions/snackbar-with-actions.component';
import {OptionsCollaborateurComponent} from './dialogs/options-collaborateur/options-collaborateur.component'
import {
  TableCoachingDateReservedComponent
} from './components/card-coaching-date-reserved/table-coaching-date-reserved.component';
import { MatTableModule } from '@angular/material/table';

// Shared components
export const components = [
  NavbarComponent,
  BandeauComponent,
  FooterComponent,
  BottomSheetComponent,
  BandeauBasComponent,
  CustomCheckboxComponent,
  CardModuleComponent,
  CardCaptationComponent,
  CardModuleMediathequeComponent,
  CaptationViewerComponent,
  ConfirmationComponent,
  AjouterCommentaireComponent,
  AjouterTempsComponent,
  AjouterProduitComponent,
  AjouterClientComponent,
  AjouterLicenceComponent,
  AjouterCollaborateurComponent,
  AffecterCoachComponent,
  AffecterLicenceComponent,
  AfficherBilanComponent,
  ModifierClientComponent,
  ModifierCollaborateurComponent,
  CardCoachingDateComponent,
  TableCoachingDateReservedComponent,
  CoachingDateEditComponent,
  CoachingApprenantRdvComponent,
  FirstLetterPipe,
  SnackBarWithActionsComponent
]

// Angular base modules
export const baseModules = [RouterModule, FormsModule, ReactiveFormsModule]

// Material modules
export const materialModules = [
  MatGridListModule,
  MatIconModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatButtonModule,
  MatDatepickerModule,
  NgbTimepickerModule,
  MatSelectModule,
  MatCheckboxModule,
  MatTabsModule,
  FontAwesomeModule,
  MatExpansionModule,
  CdkAccordionModule,
  MatProgressSpinnerModule,
  MatCardModule
]

@NgModule({
  declarations: [...components, OptionsCollaborateurComponent],
  imports: [CommonModule, ...baseModules, ...materialModules, MatTableModule],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        verticalPosition: 'bottom',
        horizontalPosition: 'end',
        duration: 3000,
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
  ],
  exports: [...components, RouterModule, ...baseModules, ...materialModules],
})
export class SharedModule {
}
