import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {takeUntil} from 'rxjs'

import {AuthenticationService} from 'src/app/core/services/commons/authentication.service'
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service'

import {Utilisateur} from 'src/app/shared/models/utilisateur.models'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends DestroyedService implements OnInit {
  screenWidth: number = 0
  currentUser: Utilisateur | undefined
  tabs: any[] = []
  tabPatadome: any[] = [
    {id: 'accueil', routerLink: '/accueil', label: 'Accueil', active: true},
    {
      id: 'formation',
      routerLink: '/formation',
      label: 'Formation',
      active: false,
      child: [
        {
          id: 'formation',
          routerLink: '/formation',
          label: 'Mon parcours',
          active: false,
        },
        {
          id: 'mediatheque',
          routerLink: '/formation/mediatheque',
          label: 'Ma médiathèque',
          active: false,
        },
        {
          id: 'synthese',
          routerLink: '/formation/synthese',
          label: 'Ma synthèse',
          active: false,
        },
      ],
    },
    {
      id: 'history',
      routerLink: '/coaching/history',
      label: 'Coaching',
      active: false,
    },
    {id: 'clients', routerLink: '/clients', label: 'Clients', active: false},
    {
      id: 'licences',
      routerLink: '/licences',
      label: 'Licences',
      active: false,
    },
    {
      id: 'produits',
      routerLink: '/produits',
      label: 'Produits',
      active: false,
    },
    {
      id: 'profil',
      routerLink: '/profile',
      label: 'Mon Profil',
      active: false,
    },
  ]
  tabCoach: any[] = [
    {id: 'accueil', routerLink: '/accueil', label: 'Accueil', active: true},
    {
      id: 'clients',
      routerLink: '/clients',
      label: 'Mes clients',
      active: false,
    },
    {
      id: 'coaching',
      routerLink: '/coaching/coach-date',
      label: 'Coaching',
      active: false,
      child: [
        {
          id: 'calendar',
          routerLink: '/coaching/coach-date',
          label: 'Mon calendrier',
          active: false,
        },
        {
          id: 'bilan',
          routerLink: '/coaching/bilan',
          label: 'Mes bilans coaching',
          active: false,
        },
        {
          id: 'history',
          routerLink: '/coaching/history',
          label: 'Historique',
          active: false,
        },
      ],
    },
    {
      id: 'profil',
      routerLink: '/profile',
      label: 'Mon Profil',
      active: false,
    },
  ]
  tabAdminClient: any[] = [
    {id: 'accueil', routerLink: '/accueil', label: 'Accueil', active: true},
    {
      id: 'collaborateurs',
      routerLink: '/clients/collaborateurs/',
      label: 'Mes collaborateurs',
      active: false,
    },
    {
      id: 'licences',
      routerLink: '/licences',
      label: 'Mes licences',
      active: false,
    },
    {
      id: 'profil',
      routerLink: '/profile',
      label: 'Mon Profil',
      active: false,
    },
  ]
  tabApprenant: any[] = [
    {id: 'accueil', routerLink: '/accueil', label: 'Accueil', active: true},
    {
      id: 'formation',
      routerLink: '/formation',
      label: 'Formation',
      active: false,
      child: [
        {
          id: 'formation',
          routerLink: '/formation',
          label: 'Mon parcours',
          active: false,
        },
        {
          id: 'mediatheque',
          routerLink: '/formation/mediatheque',
          label: 'Ma médiathèque',
          active: false,
        },
        {
          id: 'synthese',
          routerLink: '/formation/synthese',
          label: 'Ma synthèse',
          active: false,
        },
      ],
    },
    {
      id: 'coaching',
      routerLink: '/coaching/coach-date',
      label: 'Coaching',
      active: false,
      child: [
        {
          id: 'coaching',
          routerLink: '/coaching/apprenant-date',
          label: 'Prendre rendez-vous',
          active: false,
        },
        {
          id: 'bilan',
          routerLink: '/coaching/bilan',
          label: 'Mes bilans coaching',
          active: false,
        },
      ],
    },
    {
      id: 'profil',
      routerLink: '/profile',
      label: 'Mon Profil',
      active: false,
    },
  ]
  selectedTab: any

  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) {
    super()
    // Get current user
    this.authService.currentUser
      .pipe(takeUntil(this.destroyed))
      .subscribe((user) => {
        // Save user
        this.currentUser = user
        if (this.currentUser != null) {
          if (this.currentUser?.role == 'PATADOME') {
            this.tabs = this.tabPatadome
          } else if (this.currentUser?.role == 'COACH') {
            this.tabs = this.tabCoach
          } else if (this.currentUser?.role == 'ADMINISTRATEUR_CLIENT') {
            const index = this.tabAdminClient.findIndex(
              (item) => item.id == 'collaborateurs',
            )
            this.tabAdminClient[index].routerLink =
              '/clients/collaborateurs/' + this.currentUser.idClient
            this.tabs = this.tabAdminClient
          } else {
            // Apprenant
            this.tabs = this.tabApprenant
          }

          if (this.currentUser.isTesteur) {
            if (this.tabs.findIndex((item) => item.id == 'formation') === -1) {
              this.tabs.splice(1, 0, {
                id: 'formation',
                routerLink: '/formation',
                label: 'Formation',
                active: false,
                child: [
                  {
                    id: 'formation',
                    routerLink: '/formation',
                    label: 'Mon parcours',
                    active: false,
                  },
                  {
                    id: 'mediatheque',
                    routerLink: '/formation/mediatheque',
                    label: 'Ma médiathèque',
                    active: false,
                  },
                  {
                    id: 'synthese',
                    routerLink: '/formation/synthese',
                    label: 'Ma synthèse',
                    active: false,
                  },
                ],
              });
            }
          }
        }
      })
  }

  ngOnInit(): void {
    this.tabs.forEach((item) => {
      if (this.router.url.includes(item.routerLink)) {
        this.onNavigate(item, null)
      } else if (
        this.router.url.startsWith('/clients/') &&
        item.routerLink.startsWith('/clients/')
      ) {
        this.onNavigate(item, null)
      } else if (
        this.router.url.startsWith('/coaching/') &&
        item.routerLink.startsWith('/coaching/')
      ) {
        this.onNavigate(item, null)
      }
      if (item.child && item.child.length > 0) {
        item.child.forEach((subItem) => {
          if (this.router.url.includes(subItem.routerLink)) {
            this.onNavigate(item, subItem)
          }
        })
      }
    })
  }

  onNavigate(tab: any, subTab: any) {
    this.tabs.forEach((item) => {
      item.active = false
      if (item.child && item.child.length > 0) {
        item.child.forEach((subItem) => {
          subItem.active = false
        })
      }
    })
    if (subTab != null) {
      subTab.active = true
      tab.active = true
    } else {
      tab.active = true
    }
  }
}
