<h1 mat-dialog-title>Création de produit</h1>
<div [formGroup]="control" class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nom du produit</mat-label>
        <input formControlName="nom" matInput>
        <mat-error *ngIf="nom.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Formation</mat-label>
        <mat-select formControlName="formation" name="formation">
          <mat-option *ngFor="let pFormation of formations" [value]="pFormation.id">
            {{pFormation.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Durée</mat-label>
        <input formControlName="duree" matInput>
        <mat-error *ngIf="duree.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nombre de coachings</mat-label>
        <input formControlName="nbCoaching" matInput>
        <mat-error *ngIf="nbCoaching.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nombre jours entre modules</mat-label>
        <input formControlName="nbJourEntreModule" matInput>
        <mat-error *ngIf="nbJourEntreModule.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Enregistrer</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
