import {Component, Inject, inject} from '@angular/core'
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar'

@Component({
  selector: 'app-snackbar-with-actions',
  templateUrl: './snackbar-with-actions.component.html',
  styleUrls: ['./snackbar-with-actions.component.scss'],
})
export class SnackBarWithActionsComponent {
  snackBarRef = inject(MatSnackBarRef)

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { text: string, action: string }) {

  }
}
