import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {Utilisateur} from 'src/app/shared/models/utilisateur.models';
import {NavbarFormationAccessDTO} from 'src/app/shared/models/dto/DTO.models';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  constructor(private http: HttpClient) {
  }

  getUtilisateur(): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(`${environment.apiUrl}/Utilisateur/me`);
  }

  login(mail: string, motDePasse: string): Observable<Utilisateur> {
    return this.http.post<any>(`${environment.apiUrl}/Utilisateur/login`, {mail, motDePasse});
  }

  getUtilisateurById(id: number): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(`${environment.apiUrl}/Utilisateur/${id}`);
  }

  postUtilisateur(utilisateur: Utilisateur): Observable<any> {
    return this.http.post(`${environment.apiUrl}/Utilisateur`, utilisateur);
  }

  putUtilisateur(utilisateur: Utilisateur): Observable<any> {
    return this.http.put<Utilisateur>(`${environment.apiUrl}/Utilisateur`, utilisateur);
  }

  patchUtilisateur(utilisateur: Utilisateur): Observable<any> {
    return this.http.patch<Utilisateur>(`${environment.apiUrl}/Utilisateur/reverseActive/${utilisateur.id}`, null);
  }

  delete(utilisateur: Utilisateur): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Utilisateur/${utilisateur.id}`);
  }

  hasFormationAccess(): Observable<NavbarFormationAccessDTO> {
    return this.http.get<NavbarFormationAccessDTO>(`${environment.apiUrl}/Utilisateur/formation/access`);
  }

  setFirstUsageLicence(idLicence: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/Utilisateur/first-use/${idLicence}`);
  }

  forgotPassword(user: Utilisateur): Observable<any> {
    return this.http.put(`${environment.apiUrl}/Utilisateur/forgot-password`, user);
  }

  // PROFILE PART

  updateProfil(profil: Utilisateur): Observable<Utilisateur> {
    return this.http.put<Utilisateur>(`${environment.apiUrl}/Utilisateur/profil`, profil)
  }

  removeProfilProgression(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/Utilisateur/progression/${id}`);
  }
}
