import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs';

import {FormationService} from 'src/app/core/services/webservices/formation.service';
import {AuthenticationService} from 'src/app/core/services/commons/authentication.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';
import {FormatService} from 'src/app/core/services/commons/format.service';

import {Module} from 'src/app/shared/models/module.models';
import {Utilisateur} from 'src/app/shared/models/utilisateur.models';
import {ModuleCategoryEnum} from 'src/app/shared/models/enums/Enums.enum';
import { ModuleDTO } from '../../models/dto/DTO.models';

@Component({
  selector: 'app-card-module-mediatheque',
  templateUrl: './card-module-mediatheque.component.html',
  styleUrls: ['./card-module-mediatheque.component.scss']
})
export class CardModuleMediathequeComponent extends DestroyedService implements OnInit {

  @Input('module') module!: ModuleDTO;

  @Output() moduleSelected = new EventEmitter<Module>();

  user: Utilisateur | undefined;

  score: number | undefined;
  energy: number | undefined;
  hasDescription: boolean = false;
  isChecked: boolean = false;
  isEnabled: boolean = true;

  constructor(
    private router: Router,
    private formatService: FormatService,
    private formationService: FormationService,
    private authService: AuthenticationService,
  ) {
    super();
    // Get user from auth service
    this.authService.currentUser.pipe(
      takeUntil(this.destroyed)
    ).subscribe((user) => this.user = user);
  }

  ngOnInit(): void {
    // Get module score / energy
    if (this.module.moduleAvancements && this.module.moduleAvancements.length > 0) {
      if (this.user?.role !== 'PATADOME') {
        // Disable module if condition need it
        this.moduleEnabledOnCondition();
      } else {
        // If user is patadome, allow all modules
        this.isEnabled = true;
      }
      // Set existing value (for all users)
      this.getStatusFromAvancement()
    } else {
      // Else, create the module avancement
      this.formationService.createModuleAvancement(this.module.moduleId).pipe(
        takeUntil(this.destroyed)
      ).subscribe((avancement) => {
        // Set avancement
        this.module.moduleAvancements = [avancement];
        // If user isn't patadome
        if (this.user?.role !== 'PATADOME') {
          // Disable module if condition need it
          this.moduleEnabledOnCondition();
        } else {
          // If user is patadome, allow all modules
          this.isEnabled = true;
        }
        // Set new value (for all users)
        this.getStatusFromAvancement();
      })
    }
  }

  public emitModuleSelected() {
    this.moduleSelected.emit(this.module);
  }

  // Return color class by module color (centralized in style.scss)
  public getColorClassFromModuleColor(code: string, isBackground?: boolean, isBordered?: boolean): string {
    return this.formatService.getColorClassFromHexa(code, isBackground, isBordered);
  }

  // Return the description display status (depend on module category / isParcouru)
  private getStatusFromAvancement(): void {
    // If existing module and from development / prologue category
    if (this.module.category === ModuleCategoryEnum.eDEV || this.module.category === ModuleCategoryEnum.ePrologue) {
      // If module is enabled (active) display description
      this.hasDescription = this.module.moduleAvancements[0].isEnabled;
      // Set default is checked status (can be updated by a condition later in moduleEnabledOnCondition())
      this.isChecked = this.module.moduleAvancements[0].isParcourus;
    } else {
      // If module is completed display description
      this.hasDescription = this.module.moduleAvancements[0].isParcourus;
      // Set default is checked status (can be updated by a condition later in moduleEnabledOnCondition())
      this.isChecked = this.module.moduleAvancements[0].isParcourus;
    }
  }

  // Get some info from user timing / livraison produit to disabled module
  private moduleEnabledOnCondition(): void {
    this.isEnabled = this.module.isEnabled
    // Check if delivery date (first user navigation on formation module)
    if (this.user && this.user.dateLivraison) {
      const dureeUtilisation: number = new Date().getDate() - new Date(this.user.dateLivraison).getDate();
      // If duree utilisation > 84 days, disabled module
      if (dureeUtilisation > 84) {
        this.isEnabled = false;
      }
    }
  }
}
