<h1 mat-dialog-title>Modification client</h1>
<div [formGroup]="control" class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nom de l'entreprise</mat-label>
        <input formControlName="nom" matInput>
        <mat-error *ngIf="nom.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Adresse</mat-label>
        <input formControlName="adresse" matInput>
        <mat-error *ngIf="adresse.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Ville</mat-label>
        <input formControlName="ville" matInput>
        <mat-error *ngIf="ville.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Code Postale</mat-label>
        <input formControlName="codePostal" matInput maxlength="5">
        <mat-error *ngIf="codePostal.invalid">Format invalide</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Enregistrer</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
