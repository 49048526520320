import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {take, takeUntil} from 'rxjs'
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service'
import {CoachingDateService} from 'src/app/core/services/webservices/coaching-date.service'
import {CoachingDate} from '../../models/coaching-date.model'
import {CoachingEtape} from '../../models/coaching-etape.model'
import {CoachingStatusEnum, RoleEnum} from '../../models/enums/Enums.enum'
import {Utilisateur} from '../../models/utilisateur.models'

@Component({
  selector: 'app-coaching-date-edit',
  templateUrl: './coaching-date-edit.component.html',
  styleUrls: ['./coaching-date-edit.component.scss'],
})
export class CoachingDateEditComponent extends DestroyedService
  implements OnInit {
  apprenants: Utilisateur[] = []
  apprenantSelected: Utilisateur = null

  listOfStatut = ['Honoré', 'Non honoré']
  statutSelected: string = null

  listOfEtape: CoachingEtape[] = []
  etapeSelected: CoachingEtape = null

  statutEnum = CoachingStatusEnum

  motif: string = null

  roleEnum = RoleEnum

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      coach: Utilisateur
      coachingDate: CoachingDate
      me: Utilisateur
    },
    public dialogRef: MatDialogRef<CoachingDateEditComponent>,
    private coachingDateService: CoachingDateService,
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.data.coachingDate != null) {
      this.motif = this.data.coachingDate.motif
    }
    this.coachingDateService
      .getApprenantsByCoach(this.data.coach.id)
      .pipe(takeUntil(this.destroyed))
      .subscribe((res) => {
        this.apprenants = res
      })
  }

  getStatutHumanize(statut: CoachingStatusEnum): string {
    if (statut == CoachingStatusEnum.VALIDE) {
      return 'créneau validé'
    } else if (statut == CoachingStatusEnum.HONORE) {
      return 'créneau honoré (le rendez-vous à bien eu lieu)'
    } else if (statut == CoachingStatusEnum.NON_HONORE) {
      return "créneau non honoré (le rendez-vous n'a pas eu lieu"
    } else {
      return "aucun statut n'est défini"
    }
  }

  onApprenantSelected() {
    if (this.apprenantSelected != null) {
      this.coachingDateService
        .getEtapeByApprenant(this.apprenantSelected.id)
        .pipe(takeUntil(this.destroyed))
        .subscribe({
          next: (res) => {
            this.listOfEtape = res
            this.etapeSelected = this.listOfEtape[0]
          },
          error: (_) => {
            this.listOfEtape = null
            this.etapeSelected = null
          },
        })
    }
  }

  onAffectedApprenant() {
    if (this.apprenantSelected != null) {
      if (this.etapeSelected != null) {
        this.data.coachingDate.idCoachingEtape = this.etapeSelected.id
      }
      this.coachingDateService
        .affectApprenantToCoachingDate(
          this.data.coach.id,
          this.data.coachingDate.id,
          this.apprenantSelected.id,
          this.data.coachingDate.idCoachingEtape,
        )
        .pipe(take(1))
        .subscribe((res) => {
          this.dialogRef.close({action: 'update', data: res})
        })
    }
  }

  onDecommissionedApprenant() {
    this.coachingDateService
      .decommissionedApprenantToCoachingDate(
        this.data.coach.id,
        this.data.coachingDate.id,
        this.data.coachingDate.idApprenant,
      )
      .pipe(take(1))
      .subscribe((res) => {
        this.dialogRef.close({action: 'update', data: res})
      })
  }

  onCanceledCoaching() {
    this.dialogRef.close({action: 'delete', data: this.data.coachingDate})
  }

  onStatutChanged() {
    if (this.statutSelected.match(this.listOfStatut[0])) {
      // Statut Honoré
      this.data.coachingDate.statut = CoachingStatusEnum.HONORE
    } else {
      // Statut Non Honoré
      this.data.coachingDate.statut = CoachingStatusEnum.NON_HONORE
    }
    if (this.motif != null && this.motif.trim().length > 0) {
      this.data.coachingDate.motif = this.motif
    }
    this.coachingDateService
      .statutChangedCoaching(this.data.coachingDate)
      .pipe(take(1))
      .subscribe((res) => {
        this.dialogRef.close({action: 'update', data: res})
      })
  }

  close() {
    this.dialogRef.close({action: 'close'})
  }
}
