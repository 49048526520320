<div *ngIf="module && slide" class="bandeau-bas-container">
  <div class="info-block">
    <button (click)="navigateToFormation()" *ngIf="!paginate"
            className="gallery {{ getColorClassFromModuleColor(module.color, true) }}" title="Ma formation">
      <fa-icon [icon]="faPhotoVideo"></fa-icon>
    </button>
    <span *ngIf="!paginate" class="module-title">ETAPE {{ module.etapeId }} : {{ module.title }}</span>
  </div>
  <div class="action-block">
    <button (click)="previousSlide()" *ngIf="slide.backSlideId !== undefined && slide.backSlideId > 0" class="previous"
            title="Précédent">
      <fa-icon [icon]="faPrevious"></fa-icon>
    </button>
    <button
      (click)="startStop()"
      *ngIf="slide.type !== SlideTypeEnum.None && slide.type !== SlideTypeEnum.Question"
      [class.not-ready]="!isReadyToPlay"
      [class.play]="!isPlaying"
      [class.stop]="isPlaying"
      class="play"
    >
      <fa-icon *ngIf="!isPlaying" [icon]="faPlay" title="Play"></fa-icon>
      <fa-icon *ngIf="isPlaying" [icon]="faStop" title="Pause"></fa-icon>
    </button>
    <button (click)="repeatSlide()" *ngIf="slide.type !== SlideTypeEnum.None && slide.type !== SlideTypeEnum.Question"
            class="repeat" title="Revoir">
      <fa-icon [icon]="faRepeat"></fa-icon>
    </button>
    <button
      (click)="nextSlide()"
      *ngIf="slide.type !== SlideTypeEnum.Question && slide.type !== SlideTypeEnum.Video_Confirm && slide.type !== SlideTypeEnum.Cadrage && isDemo" class="next" title="Suivant">
      <fa-icon [icon]="faNext"></fa-icon>
    </button>
  </div>
  <div *ngIf="!paginate && hasScoring" class="score-block">
    <span class="etoile-value-label">{{ score }}</span>
    <img class="etoile-logo" src="/assets/Icon/Etoile_Icon.png" title="Etoile" alt="Etoile">
    <span class="etoile-label">Étoile</span>
  </div>
  <div *ngIf="paginate" class="paginate-block">
    <span class="paginate-label">Captation {{ paginate.position + 1 }} sur {{ paginate.count }}</span>
  </div>
</div>

