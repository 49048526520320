import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';
import {ParcoursDataService} from 'src/app/core/services/commons/parcours-data.service';
import {FormatService} from 'src/app/core/services/commons/format.service';

import {Slide} from 'src/app/shared/models/slide.models';
import {Module} from 'src/app/shared/models/module.models';
import {Captation} from 'src/app/shared/models/captation.models';
import {CaptationDTO, PositionDTO} from 'src/app/shared/models/dto/DTO.models';

@Component({
  selector: 'app-captation-viewer',
  templateUrl: './captation-viewer.component.html',
  styleUrls: ['./captation-viewer.component.scss']
})
export class CaptationViewerComponent extends DestroyedService implements AfterViewInit {

  // Video player reference
  @ViewChild('playVideo') playVideo: any;
  @ViewChild('playVideoRecordingReplay') playVideoRecordingReplay: any;

  // Slide displayed on screen
  slide: Slide = undefined;
  module: Module = undefined;
  isPlaying: boolean = false;
  isReadyToPlay: boolean = false;
  hasDisabledEchoes: boolean = false;

  paginate: PositionDTO;

  // Video player HTML element reference
  playVideoElement: HTMLVideoElement;
  playWatchRecordingElement: HTMLVideoElement;

  constructor(
    private formatService: FormatService,
    private parcoursDataService: ParcoursDataService,
    public dialogRef: MatDialogRef<CaptationViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CaptationDTO,
  ) {
    super();
    // Get slide from captation and module
    this.slide = this.data.captation.slide;
    this.module = this.data.captation.slide.module;
    // Get position
    this.paginate = {
      position: this.data.list.findIndex(c => c.id === this.data.captation.id),
      count: this.data.list.length
    } as PositionDTO;
  }

  public get slideUrl(): string {
    // Return access url to the video (api and key)
    return this.parcoursDataService.getSlideVideo(this.data.captation.slide.videoURL);
  }

  ngOnDestroy(): void {
    // Override method
    this.destroyed.next();
    this.destroyed.complete();
    // Destroy player
    this.playVideoElement?.pause();
    this.playVideoElement.volume = 0;
    this.playVideoElement.removeAttribute('src');
    this.playVideoElement.load();
    this.playWatchRecordingElement?.pause();
    this.playWatchRecordingElement.volume = 0;
    this.playWatchRecordingElement.removeAttribute('src');
    this.playWatchRecordingElement.load();
  }

  ngAfterViewInit(): void {
    // Get video reference
    this.playVideoElement = this.playVideo.nativeElement as HTMLVideoElement;
    this.playVideoElement.src = this.slideUrl;
    this.playVideoElement.autoplay = true;
    this.playVideoElement.volume = 1;
    this.playVideoElement.muted = false;
    // Get recording video reference
    this.playWatchRecordingElement = this.playVideoRecordingReplay.nativeElement as HTMLVideoElement;
    this.playWatchRecordingElement.src = this.slideRecordedUrl(this.data.captation);
    this.playWatchRecordingElement.autoplay = true;
    // Play the video (and emit new playing status)
    this.isPlaying = true;
    this.play();
  }

  public play(): void {
    // Play the html video
    this.playVideoElement?.play();
    if (!this.playWatchRecordingElement?.ended) {
      this.playWatchRecordingElement?.play();
    }
    this.isPlaying = true;
  }

  public pause(): void {
    // Pause the html video
    this.playVideoElement?.pause();
    this.playWatchRecordingElement?.pause();
    this.isPlaying = false;
  }

  public repeatVideo(): void {
    // Video player
    if (this.playVideoElement) {
      // Repeat the video
      this.playVideoElement?.pause();
      this.playVideoElement.currentTime = 0;
      this.playVideoElement.play();
    }
    if (this.playWatchRecordingElement) {
      // Repeat the video
      this.playWatchRecordingElement?.pause();
      this.playWatchRecordingElement.currentTime = 0;
      this.playWatchRecordingElement.play();
    }
  }

  public canPlayVideo(): void {
    // Check the html video element loading state
    this.isReadyToPlay = (this.playVideoElement?.readyState == 4 && this.playWatchRecordingElement?.readyState == 4);
  }

  public endVideo(): void {
    // On video and captation end, go to the next slide
    if (this.playVideoElement.ended && this.playWatchRecordingElement.ended) {
      this.next();
    }
  }

  previous(): void {
    // If the video isn't the first video in list
    if (this.paginate.position > 0) {
      // Replace current viewed captation
      this.data.captation = this.data.list[--this.paginate.position];
      this.slide = this.data.captation.slide;
      this.ngAfterViewInit();
    } else {
      // Close modal
      this.dialogRef.close();
    }
  }

  next(): void {
    // If the video isn't the last video in list
    if (this.paginate.position < (this.data.list.length - 1)) {
      // Replace current viewed captation
      this.data.captation = this.data.list[++this.paginate.position];
      this.slide = this.data.captation.slide;
      this.ngAfterViewInit();
    } else {
      // Close modal
      this.dialogRef.close();
    }
  }

  toggleDisableEcho(event: MatCheckboxChange): void {
    // Toggle echo button to mute original video sound, unmute on 2nd toggle
    this.hasDisabledEchoes = event.checked;
    // Set video element properties
    this.playVideoElement.volume = this.hasDisabledEchoes ? 0 : 1;
  }

  public onCancel(): void {
    // Close dialog
    this.dialogRef.close(undefined);
  }

  public slideRecordedUrl(captation: Captation): string {
    // Return access url to the video (api and key)
    return this.parcoursDataService.getRecordedVideo(captation.fileName, captation.utilisateurId);
  }

  // Return color class by module color (centralized in style.scss)
  public getColorClassFromModuleColor(code: string, isBackground?: boolean, isBordered?: boolean): string {
    return this.formatService.getColorClassFromHexa(code, isBackground, isBordered);
  }

}
