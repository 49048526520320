import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "src/app/core/guards/auth.guard";

const routes: Routes = [
// Login page
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  // Parcours components (slides)
  {
    path: 'parcours',
    loadChildren: () => import('./modules/slide/slide.module').then(m => m.SlideModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  // Backoffice components (accueil, profile, coaching etc.)
  {
    path: '',
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
];

const config: ExtraOptions = {
  useHash: false,
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
