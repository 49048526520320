<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel [expanded]="isFirstElement">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ coaching.day | date : "EEEE dd MMMM yyyy" : "UTC" : "fr-FR" }}
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon>schedule</mat-icon>
        &nbsp;Choisissez un horaire
      </mat-panel-description>
    </mat-expansion-panel-header>

    <button
      (click)="selectHoraire(coachDate)"
      *ngFor="let coachDate of coaching.listOfCoaching"
      [disabled]="coachDate.idApprenant && !hasGoodRole"
      class="button-clock"
      color="primary"
      mat-raised-button
    >
      {{ coachDate.startDate | date : "HH:mm" : "UTC" : "fr-FR" }}
      <br/>
      <p *ngIf="coachDate.idApprenant && hasGoodRole && !isPatadome">
        Statut : {{ statutEnum[coachDate.statut] }} <br/>
        Apprenant : {{ coachDate.apprenant.prenom | firstLetterPipe }}
        {{ coachDate.apprenant.nom | firstLetterPipe }}
      </p>
      <p *ngIf="coachDate.idApprenant && hasGoodRole && isPatadome">
        Statut : {{ statutEnum[coachDate.statut] }} <br/>
        Coach : {{ coachDate.coach.prenom | firstLetterPipe }} <br/>
        Apprenant : {{ coachDate.apprenant.prenom | firstLetterPipe }}
        {{ coachDate.apprenant.nom | firstLetterPipe }}
      </p>
      <p
        *ngIf="
          coachDate.idApprenant &&
          coachDate.idApprenant != me.id &&
          !hasGoodRole &&
          !isPatadome
        "
      >
        Déjà réservé
      </p>
      <p
        *ngIf="
          coachDate.idApprenant &&
          coachDate.idApprenant == me.id &&
          !hasGoodRole &&
          !isPatadome
        "
      >
        Votre rendez-vous est {{ getStatutHumanize(coachDate.statut) }} <br/>
        <a *ngIf="coachDate.statut === CoachingStatusEnum.VALIDE" [href]="coachDate.urlMeetingOnline" class="link-reunion">
            Rejoindre la réunion
        </a>
      </p>
    </button>
  </mat-expansion-panel>
</mat-accordion>
