import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from 'src/environments/environment'
import {Observable} from 'rxjs'

import {Client} from 'src/app/shared/models/client.models'
import {ClientDTO, LicenceRequestDTO,} from 'src/app/shared/models/dto/DTO.models'

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {
  }

  getClient(): Observable<any> {
    return this.http.get<Client>(`${environment.apiUrl}/Client`)
  }

  getClientArchived(): Observable<any> {
    return this.http.get<Client>(
      `${environment.apiUrl}/Client/archived`,
    )
  }

  getClientFiltred(pFilter: string): Observable<any> {
    return this.http.get<Client>(
      `${environment.apiUrl}/Client/filter/${pFilter}`,
    )
  }

  getClientById(id: number, withArchived: boolean = false): Observable<any> {
    return this.http.get<Client>(
      `${environment.apiUrl}/Client/${id}/${withArchived}`,
    )
  }

  getClientByIdFiltred(id: number, pFilter: string, withArchived: boolean = false): Observable<any> {
    return this.http.get<Client>(
      `${environment.apiUrl}/Client/filter/${id}/${pFilter}/${withArchived}`,
    )
  }

  postClient(client: ClientDTO): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Client/`, client)
  }

  putClient(client: Client): Observable<any> {
    return this.http.put<Client>(`${environment.apiUrl}/Client/`, client)
  }

  reverseActiveClient(client: Client): Observable<any> {
    return this.http.patch<any>(
      `${environment.apiUrl}/Client/reverseActive/${client.id}`,
      null,
    )
  }

  patchClient(client: Client, licence: LicenceRequestDTO[]): Observable<any> {
    return this.http.patch<any>(
      `${environment.apiUrl}/Client/${client.id}/addLicence`,
      licence,
    )
  }

  deleteClient(client: Client): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/Client/${client.id}`)
  }
}
