import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

import {ISasToken} from 'src/app/core/azure-storage/azureStorage';
import {Utilisateur} from 'src/app/shared/models/utilisateur.models';

@Injectable({
  providedIn: 'root'
})
export class ParcoursDataService {

  // Get the video url
  public getSlideVideo(filename: string): string {
    return `${environment.videos.url}/videos/${filename}?${environment.videos.key}`;
  }

  // Get the recorded captation video path
  public getRecordedVideo(filename: string, userId: number) {
    return `${environment.videos.url}/psyche-video/${environment.production ? 'prod/' : ''}${filename}?${environment.videos.storageKey}`;
  }

  // Get recorded video info object
  public getRecordedVideoInfo(fileName: string, user: Utilisateur): ISasToken {
    return {
      container: 'psyche-video',
      filename: user ? `${environment.production ? 'prod/' : ''}${user.id}/${fileName}` : fileName,
      storageAccessToken: `?${environment.videos.storageKey}`,
      storageUri: environment.videos.url,
    } as ISasToken;
  }
}
