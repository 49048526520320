import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs';

import {ProduitService} from 'src/app/core/services/webservices/produit.service';
import {FormationService} from 'src/app/core/services/webservices/formation.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Produit} from 'src/app/shared/models/produits.models';
import {Formation} from 'src/app/shared/models/formation.models';


@Component({
  selector: 'app-ajouter-produit',
  templateUrl: './ajouter-produit.component.html',
  styleUrls: ['./ajouter-produit.component.scss']
})
export class AjouterProduitComponent extends DestroyedService {

  control: FormGroup;
  nom: AbstractControl;
  duree: AbstractControl;
  nbCoaching: AbstractControl;
  nbJourEntreModule: AbstractControl;

  formations: Formation[] = [];

  constructor(
    public dialogRef: MatDialogRef<AjouterProduitComponent>,
    private serviceProduit: ProduitService,
    private formationService: FormationService,
    private snackbar: MatSnackBar,
    fb: FormBuilder,
  ) {
    super();
    // Init form
    this.control = fb.group({
      nom: ['', Validators.compose([Validators.required])],
      formation: [0, Validators.compose([Validators.required])],
      duree: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[1-9].*'),
        ]),
      ],
      nbCoaching: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[1-9].*'),
        ]),
      ],
      nbJourEntreModule: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9].*'),
        ]),
      ],
    });
    this.nom = this.control.controls['nom'];
    this.duree = this.control.controls['duree'];
    this.nbCoaching = this.control.controls['nbCoaching'];
    this.nbJourEntreModule = this.control.controls['nbJourEntreModule'];

    // Get formation
    this.formationService.getAllFormations().pipe(
      takeUntil(this.destroyed)
    )
      .subscribe((formations) => {
        // Save formation list
        this.formations = formations;
        // If there is at least 1 formation, select it by default
        if (this.formations && this.formations.length > 0) {
          this.control.controls['formation'].patchValue(this.formations[0].id);
        }
      });
  }

  onSubmit() {
    // Manual check for ngModel
    if (this.control.controls['formation'].value === 0) {
      this.snackbar.open('Veuillez sélectionner une formation pour le nouveau produit.');
      return;
    }
    if (this.control.valid) {
      const produit = {} as Produit;
      produit.actif = true;
      produit.libelle =
        this.nom.value.charAt(0).toUpperCase() +
        this.nom.value.substring(1).toLowerCase();
      produit.duree = this.duree.value;
      produit.nbCoaching = this.nbCoaching.value;
      produit.nbJourEntreModule = this.nbJourEntreModule.value;
      produit.idFormation = this.control.controls['formation'].value;
      // Controle sur l'espacement des étapes du module (en jours) pour nommer les produits 'libres' ou non
      if (this.nbJourEntreModule.value == 0 || !this.nbJourEntreModule.value) {
        produit.libelle += ' (libre)';
      }
      // Create produit
      this.serviceProduit.postProduit(produit).pipe(
        takeUntil(this.destroyed)
      )
        .subscribe((res) => {
          this.dialogRef.close(res);
          this.snackbar.open('Le produit a bien été créé !');
        });
    } else {
      this.snackbar.open('Il y a une erreur dans le formulaire, verifiez que tous les champs soient correctes.');
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
