<h1 mat-dialog-title>Ajout de temps à la licence.</h1>
<div [formGroup]="control" class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{data.licence.produit.libelle}}</mat-label>
        <input formControlName="duree" matInput>
        <mat-error *ngIf="duree.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Ok</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
