import {Injectable} from '@angular/core'
import {BehaviorSubject, map, Observable, of, takeUntil} from 'rxjs'
import {environment} from 'src/environments/environment'

import {DestroyedService} from 'src/app/core/services/commons/destroyed.service'
import {UtilisateurService} from 'src/app/core/services/webservices/utilisateur.service'

import jwt_decode from 'jwt-decode'
import {Utilisateur} from 'src/app/shared/models/utilisateur.models'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends DestroyedService {
  // Used to avoid fin slide navigation by url / previous / next navigation
  public hasParcoursEnded: boolean = false
  private readonly LOCAL_STORAGE_TOKEN: string = environment.localStorageToken

  constructor(private utilisateurService: UtilisateurService) {
    super()
    // Load user
    this.retrieveAuthUser().pipe(takeUntil(this.destroyed)).subscribe()
  }

  private _currentUser: BehaviorSubject<
    Utilisateur | undefined
  > = new BehaviorSubject<Utilisateur | undefined>(undefined)

  // @ts-ignore
  public get currentUser(): Observable<Utilisateur | undefined> {
    return this._currentUser.asObservable()
  }

  public set currentUser(user: Utilisateur | undefined) {
    // Save token
    this.saveTokenInStorage(user?.token)
    // Save user
    this._currentUser.next(user)
  }

  public static VerifyToken(): boolean {
    // Get token
    const token: string | null = localStorage.getItem(environment.localStorageToken)
    // If token doesn't exist
    if (!token || token === '') {
      return false
    } else {
      // Try to get the token validity date
      const decoded = jwt_decode(token) as any
      const decodedExp = decoded['exp']
      const date = Date.now() / 1000
      // If the token is not valid, return false => else, true
      return decodedExp >= date
    }
  }

  public logout(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN)
    this.currentUser = undefined
  }

  public isLogged(): Observable<boolean> {
    return of(AuthenticationService.VerifyToken())
  }

  // Private methods

  private saveTokenInStorage(token: string | undefined): void {
    if (!token || token === '') {
      localStorage.removeItem(this.LOCAL_STORAGE_TOKEN)
    } else {
      localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token)
    }
  }

  private retrieveAuthUser(): Observable<boolean> {
    return this.utilisateurService.getUtilisateur().pipe(
      map((user) => {
        if (user) {
          this.currentUser = user
        } else {
          // On failed retrieve user, remove potential invalid token
          // Avoid blank home page on existing token, but revoked
          this.saveTokenInStorage(undefined)
        }
        return !!user
      }),
    )
  }
}
