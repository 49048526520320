import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {mergeMap, takeUntil, tap} from 'rxjs';

import {ClientService} from 'src/app/core/services/webservices/client.service';
import {ProduitService} from 'src/app/core/services/webservices/produit.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Produit} from 'src/app/shared/models/produits.models';
import {Client} from 'src/app/shared/models/client.models';
import {LicenceRequestDTO} from 'src/app/shared/models/dto/DTO.models';

@Component({
  selector: 'app-ajouter-licence',
  templateUrl: './ajouter-licence.component.html',
  styleUrls: ['./ajouter-licence.component.scss']
})
export class AjouterLicenceComponent extends DestroyedService implements OnInit {

  produitsClient: Array<Produit> = [];
  produitsDispo: Array<Produit> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { client: Client },
    public dialogRef: MatDialogRef<AjouterLicenceComponent>,
    private serviceClient: ClientService,
    private serviceProduit: ProduitService,
    private snackbar: MatSnackBar,
  ) {
    super();
  }

  ngOnInit() {
    this.serviceProduit.getProduitByClient(this.data.client.id)
      .pipe(
        // Get active produits by clients (all)
        tap((res) => {
          for (let result of res) {
            if (result.actif) this.produitsClient.push(result);
          }
        }),
        // Get products data
        mergeMap(() => this.serviceProduit.getProduit()),
        takeUntil(this.destroyed)
      )
      .subscribe((res) => {
        for (let result of res) {
          if (result.actif) {
            result.numberOfLicence = 0;
            result.checked = false;
            for (let produit of this.produitsClient) {
              if (produit.id == result.id && produit.listOfLicence != null) {
                result.numberOfLicence = produit.listOfLicence.length;
              }
            }
            this.produitsDispo.push(result);
          }
        }
      });
  }

  onSubmit() {
    let listLicenceRequestDTO: Array<LicenceRequestDTO> = [];
    for (let produit of this.produitsDispo) {
      listLicenceRequestDTO.push({
        IdProduit: produit.id,
        Nombre: produit.numberOfLicence,
      });
    }
    this.serviceClient.patchClient(this.data.client, listLicenceRequestDTO)
      .pipe(
        takeUntil(this.destroyed)
      )
      .subscribe((res) => {
        this.dialogRef.close(res)
        this.snackbar.open('Les licences ont bien été ajouté au client !')
      });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
