import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {map, mergeMap, takeUntil} from 'rxjs';

import {FormatService} from 'src/app/core/services/commons/format.service';
import {CaptationService} from 'src/app/core/services/webservices/captation.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Captation} from 'src/app/shared/models/captation.models';
import {Module} from 'src/app/shared/models/module.models';
import {Utilisateur} from 'src/app/shared/models/utilisateur.models';
import {CaptationDTO} from 'src/app/shared/models/dto/DTO.models';
import {CaptationViewerComponent} from 'src/app/shared/dialogs/captation-viewer/captation-viewer.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-card-captation',
  templateUrl: './card-captation.component.html',
  styleUrls: ['./card-captation.component.scss'],
  providers: [DatePipe]
})
export class CardCaptationComponent extends DestroyedService {

  @Input('captation') captation!: Captation;
  @Input('module') module!: Module;

  user: Utilisateur | undefined;
  description: string;

  constructor(
    private dialog: MatDialog,
    private formatService: FormatService,
    private captationService: CaptationService,
    private datePipe: DatePipe
  ) {
    super();
    this.description = "voir la vidéo";
  }

  // Return color class by module color (centralized in style.scss)
  public getColorClassFromModuleColor(code: string, isBackground?: boolean, isBordered?: boolean): string {
    return this.formatService.getColorClassFromHexa(code, isBackground, isBordered);
  }

  public getCaptationDate(): string {
    const date: Date = new Date(this.captation.date);
    if (typeof date === 'object' && date !== null && 'getDate' in date) {
      return this.datePipe.transform(date, 'dd-MM-yyyy HH:mm:ss');
    } else {
      return "not a date";
    }
  }

  public openCaptationDialog(): void {
    // Get captation list for the module
    this.captationService.getCaptationByModule(this.module.moduleId).pipe(
      // Create dialog reference
      map((captations) => {
        // Open captation modale
        return this.dialog.open(CaptationViewerComponent, {
          width: '80%',
          height: '90%',
          data: {
            captation: this.captation,
            list: captations
          } as CaptationDTO,
        })
      }),
      mergeMap((dialog) => dialog.afterClosed()),
      takeUntil(this.destroyed)
    )
      .subscribe();
  }
}
