<h1 mat-dialog-title>Ajouter un commentaire</h1>
<div [formGroup]="commentaireForm" class="container-fluid" mat-dialog-content>
  <div class="commentaire-container">
    <mat-form-field appearance="outline" class="full-width">
            <textarea #autosize="cdkTextareaAutosize"
                      cdkAutosizeMaxRows="5"
                      cdkAutosizeMinRows="5"
                      cdkTextareaAutosize
                      formControlName="commentaire"
                      matInput></textarea>
      <mat-error *ngIf="commentaireForm.controls['commentaire'].invalid">Champ obligatoire</mat-error>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Enregistrer</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
