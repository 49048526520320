<button (click)="close()" class="close" mat-button>
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>
  Coaching sélectionné pour la date du
  {{
  data.coachingDate.startDate | date : "dd/MM/yyyy à HH:mm" : "UTC" : "fr-FR"
  }}
</h1>
<div class="container-fluid" mat-dialog-content>
  <div class="row">
    <div *ngIf="isWaiting" class="col-12 text-center">
      <mat-progress-spinner class="spinner" color="accent" mode="indeterminate">
      </mat-progress-spinner>
      <p>Vérification en cours</p>
    </div>
    <div *ngIf="!isWaiting && !isCheckOk" class="col-12 error-reason">
      <h3>
        <mat-icon>report</mat-icon>&nbsp;{{ reason }}</h3>
      <p>
        Veuillez prendre contact avec votre coach si ce n'est pas le cas :
        <a href="mailto:{{ this.data.coach.mail }}" target="_blank">
          {{ this.data.coach.mail }}
        </a>
      </p>
    </div>
    <div *ngIf="!isWaiting && isCheckOk" class="col-12">
      <h3>Vérification terminée</h3>
      <p class="warning-encart">
        Attention, un rendez-vous vous engage à l'honorer. Le cas échéant, il
        sera facturé comme s'il avait eu lieu.<br/>
        Vous avez jusqu'à 48h avant votre rendez-vous pour le faire modifier en
        prenant contact avec votre coach :
        <a href="mailto:{{ this.data.coach.mail }}" target="_blank">
          {{ this.data.coach.mail }}
        </a>
      </p>
    </div>
  </div>
</div>
<mat-dialog-actions *ngIf="!isWaiting && isCheckOk" align="end">
  <button *ngIf="!inProgress" mat-button mat-dialog-close>Annuler</button>
  <button (click)="validate()" *ngIf="!inProgress" cdkFocusInitial color="primary" mat-button>
    Valider le rendez-vous
  </button>
  <button *ngIf="inProgress" class="button-sync" color="primary" disabled="true" mat-fab>
    <mat-icon>sync</mat-icon>
  </button>
</mat-dialog-actions>
