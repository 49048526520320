<h1 mat-dialog-title>Affectation coach</h1>
<div class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col">
      <p>{{data.collaborateur.prenom}} {{data.collaborateur.nom}} , {{data.collaborateur.fonction}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [(ngModel)]="value" name="coach">
          <mat-option *ngFor="let coach of coachs" [value]="coach.id">
            {{coach.prenom}} {{coach.nom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Enregistrer</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
