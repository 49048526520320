import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Router} from '@angular/router';
import {
  faChevronLeft,
  faChevronRight,
  faPhotoVideo,
  faPlay,
  faRotateRight,
  faStop
} from '@fortawesome/free-solid-svg-icons';

import {FormatService} from 'src/app/core/services/commons/format.service';

import {PositionDTO, SlideDataDTO} from 'src/app/shared/models/dto/DTO.models';
import { ModuleCategoryEnum, SlideTypeEnum } from 'src/app/shared/models/enums/Enums.enum';

@Component({
  selector: 'app-bandeau-bas',
  templateUrl: './bandeau-bas.component.html',
  styleUrls: ['./bandeau-bas.component.scss']
})
export class BandeauBasComponent implements OnInit {

  @Input('module') module: any;
  @Input('slide') slide: any;
  @Input('isPlaying') isPlaying!: boolean;
  @Input('isReadyToPlay') isReadyToPlay!: boolean;
  @Input('score') score: number = 0;
  @Input('paginate') paginate?: PositionDTO = undefined;
  // True when user is patadome or bandeau is from a mediatheque replay
  @Input('isDemo') isDemo: boolean = false;

  @Output('play') play: EventEmitter<void> = new EventEmitter();
  @Output('pause') pause: EventEmitter<void> = new EventEmitter();
  @Output('next') next: EventEmitter<SlideDataDTO> = new EventEmitter();
  @Output('previous') previous: EventEmitter<SlideDataDTO> = new EventEmitter();
  @Output('repeat') repeat: EventEmitter<void> = new EventEmitter();

  hasScoring: boolean = false;

  faPlay = faPlay;
  faStop = faStop;
  faNext = faChevronRight;
  faPrevious = faChevronLeft;
  faRepeat = faRotateRight;
  faPhotoVideo = faPhotoVideo;

  SlideTypeEnum = SlideTypeEnum;

  constructor(
    private router: Router,
    private formatService: FormatService,
  ) {
  }

  ngOnInit(): void {
    // Set the scoring display
    this.hasScoring = ((!this.module.isTutoriel) && this.module.category !== ModuleCategoryEnum.eDEV);
  }

  navigateToFormation() {
    this.router.navigateByUrl('/formation');
  }

  // Return color class by module color (centralized in style.scss)
  public getColorClassFromModuleColor(code: string, isBackground?: boolean, isBordered?: boolean): string {
    return this.formatService.getColorClassFromHexa(code, isBackground, isBordered);
  }

  startStop(): void {
    // If playing, toggle statements
    if (this.isPlaying) {
      this.pause.emit();
    } else {
      this.play.emit();
    }
  }

  nextSlide(): void {
    // Navigate to next slide
    this.next.emit({
      moduleId: this.module.moduleId,
      slideIndex: this.slide.slideId,
      responses: []
    } as SlideDataDTO);
  }

  previousSlide(): void {
    // Navigate to previous slide
    this.previous.emit({
      moduleId: this.module.moduleId,
      slideIndex: this.slide.slideId,
      responses: []
    } as SlideDataDTO);
  }

  repeatSlide(): void {
    // Reset the current slide display
    this.repeat.emit();
  }
}
