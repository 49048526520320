import {Component, Inject} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs';

import {LicenceService} from 'src/app/core/services/webservices/licence.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Licence} from 'src/app/shared/models/licence.models';

@Component({
  selector: 'app-ajouter-temps',
  templateUrl: './ajouter-temps.component.html',
  styleUrls: ['./ajouter-temps.component.scss']
})
export class AjouterTempsComponent extends DestroyedService {

  control: FormGroup;
  duree: AbstractControl;

  constructor(
    public dialogRef: MatDialogRef<AjouterTempsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { licence: Licence },
    private snackbar: MatSnackBar,
    private serviceLicence: LicenceService,
    fb: FormBuilder,
  ) {
    super();
    // Init form
    this.control = fb.group({
      duree: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[1-9].*'),
        ]),
      ],
    });
    this.duree = this.control.controls['duree'];
  }

  onSubmit() {
    if (this.control.valid) {
      if (this.data.licence.actif) {
        this.serviceLicence.putLicence(this.data.licence.id, this.duree.value).pipe(
          takeUntil(this.destroyed)
        )
          .subscribe((res) => {
            this.dialogRef.close(res)
            this.snackbar.open('La licence a bien été mise à jour !');
          })
      } else {
        this.snackbar.open('La licence n\'a pas encore été activée.');
      }
    } else {
      this.snackbar.open('Il y a une erreur dans le formulaire, merci de vérifier tous les champs.');
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
