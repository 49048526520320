<div (click)="startModule()"
     [class.disabled]="!isEnabled"
     className="lesson-block {{ getColorClassFromModuleColor(module.color, true, true) }}">
  <div className="lesson-logo-container {{ getColorClassFromModuleColor(module.color, true) }}">
    <img alt="Logo de la leçon" class="lesson-logo" src="{{ module.icon }}">
  </div>
  <div class="lesson-info">
    <div class="lesson-line">
      <span class="lesson-title {{ getColorClassFromModuleColor(module.color, false) }}">{{ module.title }}</span>
      <div class="lesson-status">
        <mat-icon class="lesson-status-icon">access_time</mat-icon>
        <span class="lesson-time-label">≈ {{ (module.tempsEstime) }} min.</span>
        <mat-checkbox [checked]="isChecked" class="module-checked" disabled="true"></mat-checkbox>
      </div>
    </div>
    <div class="lesson-line">
      <div class="lesson-label-action">
        <img *ngIf="hasDescription && module.moduleDescriptions[0]" alt="Logo de visualisation du cours"
             class="lesson-label-action-icon" src="/assets/Icon/Item_Description_Icon.png">
        <span *ngIf="hasDescription && module.moduleDescriptions[0]"
              class="lesson-label-action-span">{{ module.moduleDescriptions[0].value.toUpperCase() }}</span>
      </div>
      <div class="lesson-stats">
        <span *ngIf="hasScoring" class="lesson-star-value">{{ score }} / {{ scoreTotal }}</span>
        <img *ngIf="hasScoring" alt="Logo du nombre de points sur la leçon" class="lesson-stat-star"
             src="/assets/Icon/Etoile_Icon.png">
        <span *ngIf="module.moduleAvancements[0].isParcourus" class="lesson-energy-value">{{ energy }} %</span>
        <img *ngIf="module.moduleAvancements[0].isParcourus" alt="Logo du score sur la leçon" class="lesson-stat-energy"
             src="/assets/Icon/Energie_Icon.png">
      </div>
    </div>
  </div>
</div>
