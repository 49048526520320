import {Pipe, PipeTransform} from '@angular/core'

@Pipe({name: 'firstLetterPipe', pure: false})
export class FirstLetterPipe implements PipeTransform {

  transform(value: string): string {
    let first = value[0].toUpperCase()
    return first + value.substring(1, value.length).toLowerCase()
  }
}
