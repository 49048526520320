import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs';

import {CoachService} from 'src/app/core/services/webservices/coach.service';
import {UtilisateurService} from 'src/app/core/services/webservices/utilisateur.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Utilisateur} from 'src/app/shared/models/utilisateur.models';

@Component({
  selector: 'app-affecter-coach',
  templateUrl: './affecter-coach.component.html',
  styleUrls: ['./affecter-coach.component.scss']
})
export class AffecterCoachComponent extends DestroyedService implements OnInit {

  coachs: Array<Utilisateur> = [];
  value: number | undefined;

  constructor(
    private coachService: CoachService,
    private utilisateurService: UtilisateurService,
    public dialogRef: MatDialogRef<AffecterCoachComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { collaborateur: Utilisateur },
  ) {
    super();
    // Get collaborateur coach id
    if (data.collaborateur.idCoach != null) {
      this.value = data.collaborateur.idCoach;
    }
  }

  ngOnInit() {
    this.coachService.getCoach().pipe(
      takeUntil(this.destroyed)
    )
      .subscribe((res) => {
        for (let result of res) {
          this.coachs.push(result);
        }
      });
  }

  onSubmit() {
    if (this.value) {
      // Set coach
      this.coachService.putCoach(this.value, this.data.collaborateur.id).pipe(
        takeUntil(this.destroyed)
      )
        .subscribe((res) => {
          this.dialogRef.close(res);
          this.snackBar.open('Le coach a bien été affecté !');
        });
    } else {
      this.snackBar.open('Aucun coach n\'est sélectionné');
    }
  }

  onCancel() {
    if (this.data.collaborateur.idCoach != null) {
      this.utilisateurService.getUtilisateurById(this.data.collaborateur.idCoach).pipe(
        takeUntil(this.destroyed)
      )
        .subscribe((res) => {
          this.dialogRef.close(res);
        });
    } else {
      this.dialogRef.close();
    }
  }
}
