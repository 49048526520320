import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent implements OnInit {

  @Input() name: string = '';
  @Output() checkedChange = new EventEmitter<boolean>();
  @Output() numberOfLicenceChange = new EventEmitter<number>();
  checkedValue: boolean = false;
  numberOfLicenceValue = 0;

  // Checked value accessors
  @Input()
  get checked() {
    return this.checkedValue;
  }

  set checked(val) {
    if (val != null && val) {
      this.numberOfLicence = 1;
    }
    if (!this.checked) {
      this.numberOfLicenceValue = 0;
    }
    this.checkedValue = val;
    this.checkedChange.emit(this.checkedValue);
  }

  // Number of licence accessors
  @Input()
  get numberOfLicence() {
    return this.numberOfLicenceValue;
  }

  set numberOfLicence(val) {
    if (val != null && val == 0) {
      this.checked = false;
    }
    this.numberOfLicenceValue = val;
    this.numberOfLicenceChange.emit(this.numberOfLicenceValue);
  }

  ngOnInit() {
    // Avoid useless licence add
    this.checkAction();
  }

  checkAction() {
    if (!this.checked || this.checked == undefined) {
      this.numberOfLicence = 0;
      this.numberOfLicenceValue = 0;
    }
  }
}
