import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

@Injectable({providedIn: 'root'})
export class JwtInterceptor extends DestroyedService implements HttpInterceptor {

  private readonly LOCAL_STORAGE_TOKEN: string = environment.localStorageToken;

  constructor() {
    super();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (localStorage.getItem(this.LOCAL_STORAGE_TOKEN)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem(this.LOCAL_STORAGE_TOKEN)}`
        }
      });
    }
    return next.handle(request);
  }
}
