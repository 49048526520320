<button (click)="close()" class="close" mat-button>
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>
  Coaching du
  {{
  data.coachingDate.startDate | date : "dd/MM/yyyy HH:mm" : "UTC" : "fr-FR"
  }}
</h1>
<div class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <mat-accordion *ngIf="data.coachingDate" multi>
        <mat-expansion-panel *ngIf="data.coachingDate.apprenant">
          <mat-expansion-panel-header>
            <mat-panel-title> Détail du rendez-vous</mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Prénom : {{ data.coachingDate.apprenant.prenom | firstLetterPipe
            }}<br/>
            Nom : {{ data.coachingDate.apprenant.nom | firstLetterPipe }}<br/>
            Fonction : {{ data.coachingDate.apprenant.fonction }}<br/>
            Société : {{ data.coachingDate.apprenant.societe }}
          </p>
          <p *ngIf="data.coachingDate.urlMeetingOnline">
            Url de la réunion :
            <a [href]="data.coachingDate.urlMeetingOnlineHote">{{
              data.coachingDate.urlMeetingOnlineHote
              }}</a>
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel
          *ngIf="data.me.role != roleEnum[roleEnum.PATADOME]"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Affecter ou changer l'apprenant sur ce créneau
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="data.coachingDate.apprenant">
            <p>
              Attention, il est de votre responsabilité d'entrer en contact avec
              l'apprenant pour l'informer de cette désaffectation à ce créneau.
              <br/>
              Prénom : {{ data.coachingDate.apprenant.prenom | firstLetterPipe
              }}<br/>
              Nom : {{ data.coachingDate.apprenant.nom | firstLetterPipe
              }}<br/>
              Email de l'apprenant :
              <a
                href="mailto:{{ data.coachingDate.apprenant.mail }}"
                target="_blank"
              >{{ data.coachingDate.apprenant.mail }}</a
              >
            </p>
            <button
              (click)="onDecommissionedApprenant()"
              color="accent"
              mat-raised-button
              type="submit"
            >
              Désaffecter l'apprenant
            </button>
          </div>
          <br/>
          <br/>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Sélectionner l'apprenant</mat-label>
            <mat-select
              (ngModelChange)="onApprenantSelected()"
              [(ngModel)]="apprenantSelected"
            >
              <mat-option
                *ngFor="let apprenant of apprenants"
                [value]="apprenant"
              >
                {{ apprenant.prenom }} {{ apprenant.nom }}
                {{ apprenant.societe }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="listOfEtape && listOfEtape.length > 0" appearance="fill" class="full-width">
            <mat-label
            >Sélectionner l'étape correspondante au coaching
            </mat-label
            >
            <mat-select [(ngModel)]="etapeSelected">
              <mat-option *ngFor="let etape of listOfEtape" [value]="etape">
                {{ etape.libelle }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <p *ngIf="listOfEtape == null">
            Cet étudiant n'a plus de coaching restant dans son parcours.<br/>
            Vous pouvez néanmoins forcer le système et lui affecter un coaching.
          </p>
          <button
            (click)="onAffectedApprenant()"
            color="primary"
            mat-raised-button
            type="submit"
          >
            Valider le changement
          </button>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="data.coachingDate.apprenant">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Validation que la réunion a bien eu lieu (ou non)
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Statut actuel : {{ getStatutHumanize(data.coachingDate.statut) }}
          </p>
          <p *ngIf="data.coachingDate.motif">
            Commentaire sur le statut actuel : {{ data.coachingDate.motif }}
          </p>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Sélectionner le nouveau statut</mat-label>
            <mat-select [(ngModel)]="statutSelected">
              <mat-option *ngFor="let statut of listOfStatut" [value]="statut">
                {{ statut }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Commentaire lié au changement de statut</mat-label>
            <input [(ngModel)]="motif" matInput/>
            <mat-icon matSuffix>rate_review</mat-icon>
          </mat-form-field>
          <button
            (click)="onStatutChanged()"
            color="primary"
            mat-raised-button
            type="submit"
          >
            Valider le changement
          </button>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Suppression de la séance</mat-panel-title>
          </mat-expansion-panel-header>
          <p *ngIf="data.coachingDate.apprenant">
            Attention, il est de votre responsabilité d'entrer en contact avec
            l'apprenant pour l'informer de votre annulation. <br/>
            Prénom : {{ data.coachingDate.apprenant.prenom | firstLetterPipe
            }}<br/>
            Nom : {{ data.coachingDate.apprenant.nom | firstLetterPipe }}<br/>
            Email de l'apprenant :
            <a
              href="mailto:{{ data.coachingDate.apprenant.mail }}"
              target="_blank"
            >{{ data.coachingDate.apprenant.mail }}</a
            >
          </p>
          <button (click)="onCanceledCoaching()" color="warn" mat-raised-button>
            Supprimer cette séance
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
