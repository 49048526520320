export enum ModuleCategoryEnum {
  eVAL,
  eDEV,
  eXER,
  eCERT,
  ePrologue,
}

export enum SlideTypeEnum {
  None,
  Video,
  Question,
  SplitScreen,
  Framing,
  Coaching,
  Cadrage,
  Replay,
  Multiple_Replay,
  Solo_Captation,
  Video_Confirm,
}

export enum ConditionTypeEnum {
  MIN,
  MAX,
  EQUAL,
  MAX_OR_EQUAL,
  MIN_OR_EQUAL,
  PASSAGE_PAR,
  PASSAGE_PAR_OK,
  BACK_CAPTATION,
  BACK_PARCOURS,
}

export enum SlideResponseType {
  NONE,
  SINGLE,
  MULTIPLE,
}

export enum ResponseTypeEnum {
  CHOICE,
  RESPONSE,
  REPLAY,
}

export enum ThemeTypeEnum {
  CLIENT_AGRESSIF,
  CLIENT_MANIPULATEUR_CULPABILISATEUR,
  CLIENT_SOUMIS_CRAINTIF_ANXIEUX,
  AUTO_DIAGNOSTIC,
}

export enum RelationnelTypeEnum {
  NONE,
  STRATEGIE,
  EMOTION_MAITRISE,
  EXPRESSION,
  VISION_POSITIVE_DE_SOI,
  ECOUTE_REGARD,
  DIAGNOSTIQUE,
}

export enum SexeProfilEnum {
  HOMME,
  FEMME,
}

export enum RoleEnum {
  PATADOME,
  ADMINISTRATEUR_CLIENT,
  APPRENANT,
  COACH,
}

export enum TypeCardDashboardEnum {
  BASIC,
  USER_CALENDAR,
}

export enum CoachingStatusEnum {
  NONE,
  PROPOSE,
  VALIDE,
  REFUSE,
  HONORE,
  NON_HONORE,
}

export enum MeetingAppEnum {
  TEAMS,
  ZOOM
}

export enum RoleUtilisateurEnum {
  PATADOME = "PATADOME",
  COACH = "COACH",
  ADMINISTRATEUR = "ADMINISTRATEUR_CLIENT",
  APPRENANT = "APPRENANT"
}
