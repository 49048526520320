<nav>
  <ul class="nav nav-fill">
    <li *ngFor="let tab of tabs" [id]="tab.id" class="nav-item">
      <a
        (click)="onNavigate(tab, null)"
        [class.active]="tab.active"
        [class.disabled]="tab.disabled"
        [routerLink]="tab.routerLink"
        class="nav-link"
        data-toggle="tab"
      >
        {{ tab.label }}
      </a>
      <div
        *ngIf="tab.child"
        [class.big]="currentUser?.role === 'PATADOME'"
        [class.minimize]="currentUser?.role !== 'PATADOME'"
        class="sub-menu-container"
      >
        <a
          (click)="onNavigate(tab, subTab)"
          *ngFor="let subTab of tab.child"
          [class.active]="subTab.active"
          [routerLink]="subTab.routerLink"
        >{{ subTab.label }}</a
        >
      </div>
    </li>
  </ul>
</nav>
