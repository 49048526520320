import {Component, OnInit} from '@angular/core';
import {Utilisateur} from 'src/app/shared/models/utilisateur.models';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs';

import {AuthenticationService} from 'src/app/core/services/commons/authentication.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';
// import { HomeService } from '../../../core/services/webservices/home.service';
import {Client} from 'src/app/shared/models/client.models';
import {HomeService} from 'src/app/core/services/webservices/home.service';
import {BandeauInfoDTO} from '../../models/dto/DTO.models';
import {MeetingAppEnum} from '../../models/enums/Enums.enum';

@Component({
  selector: 'app-bandeau',
  templateUrl: './bandeau.component.html',
  styleUrls: ['./bandeau.component.scss']
})
export class BandeauComponent extends DestroyedService implements OnInit {

  currentUser: Utilisateur | undefined;
  date: Date = new Date();
  nbJoursRestant: number | undefined;
  info: BandeauInfoDTO = null;
  meetingAppEnum = MeetingAppEnum;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private homeService: HomeService
  ) {
    super();
  };

  public get currentDate(): Date {
    return new Date();
  }

  ngOnInit() {
    // Get current user
    this.authenticationService.currentUser.pipe(
      takeUntil(this.destroyed)
    )
      .subscribe(x => {
        this.currentUser = x;
        if (this.currentUser && !this.currentUser.client) {
          this.currentUser.client = {} as Client;
        }
      });

    this.homeService.getBandeauInfo().subscribe(res => {
      this.info = res;
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigateByUrl('/login');
  }
}
