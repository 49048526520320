<h1 mat-dialog-title>Affectation licence</h1>
<div class="container-fluid" mat-dialog-content>
  <div *ngIf="data.collaborateur!=null">
    <div class="row">
      <div class="col">
        <p> {{data.collaborateur.prenom}} {{data.collaborateur.nom}}, {{data.collaborateur.fonction}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="produits.length>0">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Licence</mat-label>
            <mat-select [(ngModel)]="value" name="licence">
              <mat-option *ngFor="let produit of produits" [value]="produit.id">
                {{produit.libelle}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="produits.length==0">
          <p>
            L'entreprise ne possède plus de licence.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="data.licence!=null">
  <div class="row">
    <div class="col">
      <p> {{data.licence.produit.libelle}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Licence</mat-label>
        <mat-select [(ngModel)]="value" name="licence">
          <mat-option *ngFor="let utilisateur of utilisateurs" [value]="utilisateur.id">
            <div *ngIf="utilisateur.licence==null">
              {{utilisateur.prenom}} {{utilisateur.nom}}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Enregistrer</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
