import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {Formation} from 'src/app/shared/models/formation.models';
import {ModuleAvancement} from 'src/app/shared/models/module-avancement.models';
import { FormationEtapeDTO, FormationStatsDTO } from '../../../shared/models/dto/DTO.models';

@Injectable({
  providedIn: 'root'
})
export class FormationService {

  private url: string = '/formation/';

  constructor(private http: HttpClient) {
  }

  public getAllFormations(): Observable<Formation[]> {
    return this.http.get<Formation[]>(`${environment.apiUrl}${this.url}`);
  }

  public getFormation(): Observable<FormationEtapeDTO[]> {
    return this.http.get<FormationEtapeDTO[]>(`${environment.apiUrl}${this.url}module`)
  }

  public getStatistiqueFormation(): Observable<FormationStatsDTO> {
    return this.http.get<FormationStatsDTO>(`${environment.apiUrl}${this.url}module/statistique`)
  }

  public createModuleAvancement(moduleId: number): Observable<ModuleAvancement> {
    return this.http.get<ModuleAvancement>(`${environment.apiUrl}${this.url}module-avancement/${moduleId}`)
  }

  public getLastModuleCompletion(moduleId: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}${this.url}last-completed/${moduleId}`)
  }

  public getTimingModuleValidity(moduleId: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}${this.url}timing/${moduleId}`)
  }
}
