<h1 mat-dialog-title>Attention !</h1>
<div class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col">
      <p [innerHTML]="data.text"></p>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Ok</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
