<table mat-table [dataSource]="dataSource" class="table-recap-coach">

  <ng-container matColumnDef="nom">
    <th mat-header-cell *matHeaderCellDef> Nom de l'apprenant </th>
    <td class="nomApprenant" mat-cell *matCellDef="let element">
      {{ element.apprenant.prenom | firstLetterPipe }}
      {{ element.apprenant.nom | firstLetterPipe }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td class="dateCoaching" mat-cell *matCellDef="let element">
      {{ element.startDate | date : "EEEE dd MMMM yyyy" : "UTC" : "fr-FR" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lien">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="lienVisio" mat-cell *matCellDef="let element">
      <a [href]="element.urlMeetingOnlineHote">
        <button
        class="button-clock"
        color="primary"
        mat-raised-button>
          Rejoindre la réunion
      </button>
      </a>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
