import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {catchError, map, Observable, of, takeUntil} from 'rxjs';

import {AuthenticationService} from 'src/app/core/services/commons/authentication.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

@Injectable({providedIn: 'root'})
export class AuthGuard extends DestroyedService implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authenticationService.isLogged().pipe(
      map((isLogged) => {
        if (!isLogged) {
         this.getLogout(state);
          return false;
        } else {
          // Logged in so return true
          return true
        }
      }),
      catchError((err) => {
        this.getLogout(state);
        // Return false
        return of(false);
      }),
      takeUntil(this.destroyed)
    );
  }

  getLogout(state: RouterStateSnapshot): void {
    // Reset login data
    this.authenticationService.logout();
    // Not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}}).then(() => {
      console.log('[AuthGuard] Redirect on login with default navigate')
    })
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
