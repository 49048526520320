import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {Captation} from 'src/app/shared/models/captation.models';
import {SlideDataDTO} from 'src/app/shared/models/dto/DTO.models';

@Injectable({
  providedIn: 'root'
})
export class CaptationService {

  private url: string = '/captation/';


  constructor(private http: HttpClient) {
  }

  public getCaptation(): Observable<Captation[]> {
    return this.http.get<Captation[]>(`${environment.apiUrl}${this.url}`);
  }

  public getCaptationByModule(moduleId: number): Observable<Captation[]> {
    return this.http.get<Captation[]>(`${environment.apiUrl}${this.url}${moduleId}`);
  }

  public createCaptation(slideData: SlideDataDTO): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}${this.url}`, slideData);
  }

  public getReplayCaptation(moduleId: number, slideIndex: number): Observable<Captation> {
    return this.http.get<Captation>(`${environment.apiUrl}${this.url}path/${slideIndex}/${moduleId}`)
  }

  public getMultipleReplayCaptation(slideData: SlideDataDTO): Observable<Captation[]> {
    return this.http.post<Captation[]>(`${environment.apiUrl}${this.url}multiple`, slideData)
  }
}
