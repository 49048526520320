import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-ajouter-commentaire',
  templateUrl: './ajouter-commentaire.component.html',
  styleUrls: ['./ajouter-commentaire.component.scss']
})
export class AjouterCommentaireComponent {

  commentaireForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<AjouterCommentaireComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // Init form
    this.commentaireForm = this.fb.group({
      commentaire: ['', Validators.compose([Validators.required])]
    });
  }

  public onSubmit(): void {
    // If the commentaire isn't empty
    if (this.commentaireForm.valid) {
      // Return commentaire value
      this.dialogRef.close(this.commentaireForm.controls['commentaire'].value);
    } else {
      // Prevent about empty value
      this.snackbar.open('Le commentaire ne peut pas etre vide.')
    }
  }

  public onCancel(): void {
    // Close dialog
    this.dialogRef.close(undefined);
  }
}
