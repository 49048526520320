import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from 'src/environments/environment'
import {Observable} from 'rxjs'
import {
  CheckRightCoachingDTO,
  ListCoachingDateDTO,
  ResponseCheckRightCoachingDTO,
} from 'src/app/shared/models/dto/DTO.models'
import {Utilisateur} from 'src/app/shared/models/utilisateur.models'
import {CoachingDate} from 'src/app/shared/models/coaching-date.model'
import {CoachingEtape} from 'src/app/shared/models/coaching-etape.model'

@Injectable({
  providedIn: 'root',
})
export class CoachingDateService {
  constructor(private http: HttpClient) {
  }

  getByCoach(idCoach: number): Observable<ListCoachingDateDTO[]> {
    return this.http.get<ListCoachingDateDTO[]>(
      `${environment.apiUrl}/coachingdate/by-coach/${idCoach}`,
    )
  }

  create(
    idCoach: number,
    listCoachingDateDTO: ListCoachingDateDTO,
  ): Observable<ListCoachingDateDTO[]> {
    return this.http.post<ListCoachingDateDTO[]>(
      `${environment.apiUrl}/coachingdate/create/${idCoach}`,
      listCoachingDateDTO,
    )
  }

  canceledCoaching(idCoach: number, idCoaching: number): Observable<boolean> {
    return this.http.put<boolean>(
      `${environment.apiUrl}/coachingdate/canceled/${idCoach}/${idCoaching}`,
      null,
    )
  }

  getApprenantsByCoach(idCoach: number): Observable<Utilisateur[]> {
    return this.http.get<Utilisateur[]>(
      `${environment.apiUrl}/coachingdate/apprenants/${idCoach}`,
    )
  }

  affectApprenantToCoachingDate(
    idCoach: number,
    idCoaching: number,
    idApprenant: number,
    idCoachingEtape: number = null,
  ): Observable<CoachingDate> {
    return this.http.put<CoachingDate>(
      `${environment.apiUrl}/coachingdate/affected`,
      {
        idCoach: idCoach,
        idCoaching: idCoaching,
        idApprenant: idApprenant,
        idCoachingEtape: idCoachingEtape,
      },
    )
  }

  priseRdvApprenant(
    idCoach: number,
    idCoaching: number,
    idApprenant: number,
  ): Observable<CoachingDate> {
    return this.http.put<CoachingDate>(
      `${environment.apiUrl}/coachingdate/rdv`,
      {idCoach: idCoach, idCoaching: idCoaching, idApprenant: idApprenant},
    )
  }

  decommissionedApprenantToCoachingDate(
    idCoach: number,
    idCoaching: number,
    idApprenant: number,
  ): Observable<CoachingDate> {
    return this.http.put<CoachingDate>(
      `${environment.apiUrl}/coachingdate/decommissioned`,
      {idCoach: idCoach, idCoaching: idCoaching, idApprenant: idApprenant},
    )
  }

  statutChangedCoaching(coaching: CoachingDate): Observable<CoachingDate> {
    return this.http.put<CoachingDate>(
      `${environment.apiUrl}/coachingdate/statut-changed`,
      coaching,
    )
  }

  getArchivedCoaching(year: number): Observable<ListCoachingDateDTO[]> {
    return this.http.get<ListCoachingDateDTO[]>(
      `${environment.apiUrl}/coachingdate/archived/${year}`,
    )
  }

  checkRightCoaching(
    idCoach: number,
    idCoaching: number,
    idApprenant: number,
  ): Observable<ResponseCheckRightCoachingDTO> {
    const postData: CheckRightCoachingDTO = {
      idApprenant: idApprenant,
      idCoach: idCoach,
      idCoaching: idCoaching,
    }
    return this.http.post<ResponseCheckRightCoachingDTO>(
      `${environment.apiUrl}/coachingdate/post-check-right`,
      postData,
    )
  }

  checkRightRdv(
    idCoach: number,
    idApprenant: number,
  ): Observable<ResponseCheckRightCoachingDTO> {
    return this.http.get<ResponseCheckRightCoachingDTO>(
      `${environment.apiUrl}/coachingdate/check-right-rdv/${idCoach}/${idApprenant}`,
    )
  }

  getByCoachAndApprenant(
    idCoach: number,
    idApprenant: number,
  ): Observable<CoachingDate[]> {
    return this.http.get<CoachingDate[]>(
      `${environment.apiUrl}/coachingdate/by-coach-and-apprenant/${idCoach}/${idApprenant}`,
    )
  }

  updateRemarques(
    coachingDate: CoachingDate,
    roleEnum: string,
  ): Observable<CoachingDate> {
    return this.http.put<CoachingDate>(
      `${environment.apiUrl}/coachingdate/remarques`,
      {coachingDate: coachingDate, updater: roleEnum},
    )
  }

  getEtapeByApprenant(idApprenant: number): Observable<CoachingEtape[]> {
    return this.http.get<CoachingEtape[]>(
      `${environment.apiUrl}/coachingdate/etape/${idApprenant}`,
    )
  }

  sendMailRelaunch(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/coachingdate/relaunch/${id}`)
  }
}
