<h1 mat-dialog-title>Ajout licence</h1>
<div mat-dialog-content>
  <mat-label>Licences</mat-label>
  <app-custom-checkbox *ngFor="let produit of produitsDispo" [(checked)]="produit.checked"
                       [(numberOfLicence)]="produit.numberOfLicence" [name]="produit.libelle">
  </app-custom-checkbox>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Enregistrer</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
