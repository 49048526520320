import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  // Keep as original to not create potential mismatches with final display
  public getColorClassFromHexa(code: string, isBackground?: boolean, isBordered?: boolean): string {
    // Set background OR color (not both for visibility) OR nothing
    let property: string = (isBackground === true ? 'bg-psyche ' : (isBackground === false ? 'color-psyche ' : ''));
    // If asked, add a 4px bottom border on element
    property += isBordered ? 'border-psyche ' : '';
    // Return the new property and his color
    if (code === '#faad5d') {
      return `${property}jaune`;
    } else if (code === '#f26c4f') {
      return `${property}orange`;
    } else if (code === '#ea6878') {
      return `${property}rouge`;
    } else if (code === '#00bff3') {
      return `${property}bleu`;
    } else if (code === '#0c9489') {
      return `${property}vert`;
    } else {
      return '';
    }
  }

  // Return the button style related to module category
  public getColorClassButtonFromHexa(code: string): string {
    // Generic class form buttons
    const property: string = 'full-border-psyche ';
    // Take the generic class combo related to hexa code
    if (code === '#faad5d') {
      return `${property}jaune`;
    } else if (code === '#f26c4f') {
      return `${property}orange`;
    } else if (code === '#ea6878') {
      return `${property}rouge`;
    } else if (code === '#00bff3') {
      return `${property}bleu`;
    } else if (code === '#0c9489') {
      return `${property}vert`;
    } else {
      return '';
    }
  }

  // Get formatted date for template display
  public formatDate(date: Date): string {
    // Get 2 digits day, month and year
    const day: string = this.formatNumberTwoDigits(date.getDate());
    const month: string = this.formatNumberTwoDigits(date.getMonth());
    const year: string = date.getFullYear().toString(10).slice(2);
    // Get 2 digits time
    const hours: string = this.formatNumberTwoDigits(date.getHours());
    const minutes: string = this.formatNumberTwoDigits(date.getMinutes());
    // Set date string value
    const value: string = `${day}/${month}/${year} à ${hours}:${minutes}`;
    // Return formatted date string
    return value;
  }

  // Return 2 digits string of a number value
  public formatNumberTwoDigits(value: number): string {
    // Format number with 2 digits min.
    return value.toString().padStart(2, '0');
  }
}
