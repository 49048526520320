<h1 mat-dialog-title>Ajouter un collaborateur</h1>
<div [formGroup]="control" class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nom</mat-label>
        <input formControlName="nom" matInput>
        <mat-error *ngIf="nom.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Prénom</mat-label>
        <input formControlName="prenom" matInput>
        <mat-error *ngIf="prenom.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Fonction / Groupe</mat-label>
        <input formControlName="fonction" matInput>
        <mat-error *ngIf="fonction.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Sexe</mat-label>
        <mat-select formControlName="sexe" value="{{ sexe }}">
          <mat-option value="HOMME">
            Homme
          </mat-option>
          <mat-option value="FEMME">
            Femme
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Adresse mail</mat-label>
        <input formControlName="mail" matInput>
        <mat-error *ngIf="mail.invalid">Format invalide</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Rôle</mat-label>
        <mat-select formControlName="role">
          <mat-option *ngIf="data.role=='PATADOME'" value="PATADOME">
            Patadome
          </mat-option>
          <mat-option value="ADMINISTRATEUR_CLIENT">
            Administrateur client
          </mat-option>
          <mat-option value="APPRENANT">
            Apprenant
          </mat-option>
          <mat-option *ngIf="data.role=='PATADOME'" value="COACH">
            Coach
          </mat-option>
        </mat-select>
        <mat-error *ngIf="role.invalid">Champs obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Entreprise</mat-label>
        <input formControlName="company" matInput/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Code postal de l'entreprise</mat-label>
        <input formControlName="cp" matInput/>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Ville de l'entreprise</mat-label>
        <input formControlName="city" matInput/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Tranche d'âge</mat-label>
        <mat-select
          formControlName="trancheAge"
        >
          <mat-option value="moins de 25 ans">moins de 25 ans</mat-option>
          <mat-option value="25 - 30 ans">25 - 30 ans</mat-option>
          <mat-option value="31 - 35 ans">31 - 35 ans</mat-option>
          <mat-option value="36 - 40 ans">36 - 40 ans</mat-option>
          <mat-option value="41 - 45 ans">41 - 45 ans</mat-option>
          <mat-option value="46 - 50 ans">46 - 50 ans</mat-option>
          <mat-option value="51 - 55 ans">51 - 55 ans</mat-option>
          <mat-option value="56 - 60 ans">56 - 60 ans</mat-option>
          <mat-option value="61 - 65 ans">61 - 65 ans</mat-option>
          <mat-option value="plus de 65 ans">plus de 65 ans</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onSubmit()" cdkFocusInitial color="primary" mat-raised-button type="submit">Enregistrer</button>
  <button (click)="onCancel()" color="default" mat-raised-button>Annuler</button>
</div>
