import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {BandeauInfoDTO, DashboardDataDTO} from 'src/app/shared/models/dto/DTO.models';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) {
  }

  getDatas(): Observable<DashboardDataDTO> {
    return this.http.get<DashboardDataDTO>(`${environment.apiUrl}/Home`);
  }

  getBandeauInfo(): Observable<BandeauInfoDTO> {
    return this.http.get<BandeauInfoDTO>(`${environment.apiUrl}/Home/bandeau-info`);
  }
}
