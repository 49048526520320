<h1 mat-dialog-title>Visionnage des captations</h1>
<div class="captation-container" mat-dialog-content>
  <div class="split-view-container">
    <div class="video-container">
      <!-- Video player container -->
      <video
        #playVideo
        (canplay)="canPlayVideo()"
        (ended)="endVideo()"
        (oncanplaythrough)="canPlayVideo()"
        [volume]="1"
        class="video-js video-play"
        muted="false"
        poster="/assets/Icon/Spinner.gif"
        preload="auto"
      >
        <source type="video/webm"/>
      </video>
    </div>
    <div class="captation-container">
      <!-- Video recorded container -->
      <video
        #playVideoRecordingReplay
        (canplay)="canPlayVideo()"
        (ended)="endVideo()"
        (oncanplaythrough)="canPlayVideo()"
        [volume]="1"
        class="video-js video-play"
        muted="false"
        poster="/assets/Icon/Spinner.gif"
        preload="auto"
      >
        <source type="video/webm"/>
      </video>
    </div>
  </div>
  <app-bandeau-bas (next)="next()"
                   (pause)="pause()"
                   (previous)="previous()"
                   (repeat)="repeatVideo()"
                   [isDemo]="true"
                   [isPlaying]="isPlaying"
                   [isReadyToPlay]="isReadyToPlay"
                   [module]="data.captation.slide"
                   [paginate]="paginate"
                   [slide]="data.captation.slide.module"
                   className="bandeau-bas-slide {{ getColorClassFromModuleColor(module.color, true) }}">
  </app-bandeau-bas>
</div>
<div mat-dialog-actions>
  <div class="remove-echoes-checkbox">
    <span>Supprimer l'echo</span>
    <mat-checkbox (change)="toggleDisableEcho($event)" [(ngModel)]="hasDisabledEchoes" [checked]="hasDisabledEchoes"
                  class="echo-checkbox"></mat-checkbox>
  </div>
  <div class="close-dialog">
    <button (click)="onCancel()" color="default" mat-raised-button>Quitter</button>
  </div>
</div>
