import {Component, Inject, OnInit} from '@angular/core'
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog'
import {MatSnackBar} from '@angular/material/snack-bar'
import {mergeMap, of, takeUntil} from 'rxjs'

import {UtilisateurService} from 'src/app/core/services/webservices/utilisateur.service'
import {AuthenticationService} from 'src/app/core/services/commons/authentication.service'
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service'

import {Utilisateur} from 'src/app/shared/models/utilisateur.models'
import {TitleCasePipe} from '@angular/common'
import {ConfirmationComponent} from '../confirmation/confirmation.component'
import {MatTableDataSource} from '@angular/material/table'
import {CoachService} from '../../../core/services/webservices/coach.service'
import {LicenceService} from '../../../core/services/webservices/licence.service'

@Component({
  selector: 'app-modifier-collaborateur',
  templateUrl: './modifier-collaborateur.component.html',
  styleUrls: ['./modifier-collaborateur.component.scss'],
})
export class ModifierCollaborateurComponent extends DestroyedService
  implements OnInit {
  control: FormGroup
  nom: AbstractControl
  prenom: AbstractControl
  fonction: AbstractControl
  mail: AbstractControl
  role: AbstractControl
  sexe: AbstractControl
  coach: AbstractControl
  dateFinLicence: AbstractControl
  isTesteur: AbstractControl
  company: AbstractControl
  cp: AbstractControl
  city: AbstractControl
  trancheAge: AbstractControl
  currentUser: Utilisateur | undefined

  coachs: Utilisateur[] = []

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModifierCollaborateurComponent>,
    private serviceUtilisateur: UtilisateurService,
    private authService: AuthenticationService,
    private coachService: CoachService,
    private licenceService: LicenceService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      collaborateur: Utilisateur
      role: string
      dataSource: MatTableDataSource<Utilisateur>
    },
    fb: FormBuilder,
    private snackbar: MatSnackBar,
  ) {
    super()
    // Init form
    this.control = fb.group({
      nom: [this.data.collaborateur.nom],
      prenom: [this.data.collaborateur.prenom],
      fonction: [this.data.collaborateur.fonction],
      sexe: [this.data.collaborateur.sexe],
      coach: [this.data.collaborateur.coach?.id],
      mail: [
        this.data.collaborateur.mail,
        Validators.compose([Validators.email]),
      ],
      role: [this.data.collaborateur.role],
      dateFinLicence: [this.data.collaborateur.licence?.dateFin],
      isTesteur: new FormControl(this.data.collaborateur.isTesteur),
      company: new FormControl(this.data.collaborateur.societe),
      cp: new FormControl(this.data.collaborateur.codePostal),
      city: new FormControl(this.data.collaborateur.ville),
      trancheAge: new FormControl(this.data.collaborateur.trancheAge)
    })
    this.nom = this.control.controls['nom']
    this.prenom = this.control.controls['prenom']
    this.fonction = this.control.controls['fonction']
    this.mail = this.control.controls['mail']
    this.role = this.control.controls['role']
    this.sexe = this.control.controls['sexe']
    this.coach = this.control.controls['coach']
    this.dateFinLicence = this.control.controls['dateFinLicence']
    this.isTesteur = this.control.controls['isTesteur']
    this.company = this.control.controls['company']
    this.cp = this.control.controls['cp']
    this.city = this.control.controls['city']
    this.trancheAge = this.control.controls['trancheAge']
  }

  ngOnInit() {
    // Get current user
    this.authService.currentUser
      .pipe(takeUntil(this.destroyed))
      .subscribe((user) => (this.currentUser = user))
    // Get coachs
    this.coachService
      .getCoach()
      .pipe(takeUntil(this.destroyed))
      .subscribe((res) => (this.coachs = res))
  }

  onSubmit() {
    if (this.control.valid) {
      this.data.collaborateur.nom =
        this.nom.value.charAt(0).toUpperCase() +
        this.nom.value.substring(1).toLowerCase()
      this.data.collaborateur.prenom =
        this.prenom.value.charAt(0).toUpperCase() +
        this.prenom.value.substring(1).toLowerCase()
      this.data.collaborateur.mail = this.mail.value
      this.data.collaborateur.fonction =
        this.fonction.value.charAt(0).toUpperCase() +
        this.fonction.value.substring(1).toLowerCase()
      this.data.collaborateur.sexe = this.sexe.value
      this.data.collaborateur.idCoach = this.coach.value
      this.data.collaborateur.coach = this.coachs.find(
        (coach) => coach.id == this.coach.value,
      )
      this.data.collaborateur.actif = true
      this.data.collaborateur.role = this.role.value
      if (this.isTesteur.value) {
        this.data.collaborateur.isTesteur = true;
      } else {
        this.data.collaborateur.isTesteur = false;
      }
      this.data.collaborateur.societe = this.company.value;
      this.data.collaborateur.codePostal = this.cp.value;
      this.data.collaborateur.ville = this.city.value;
      this.data.collaborateur.trancheAge = this.trancheAge.value;
      console.log(this.data.collaborateur);
      if (this.data.collaborateur.licence) {
        if (
          this.data.collaborateur.licence.dateFin != this.dateFinLicence.value
        ) {
          let modif = new Date(this.dateFinLicence.value)
          let init = new Date(this.data.collaborateur.licence.dateFin)
          let diff = Math.abs(modif.getTime() - init.getTime())
          let diffJours = Math.ceil(diff / (1000 * 3600 * 24))
          this.data.collaborateur.licence.dateFin = this.dateFinLicence.value
          this.serviceUtilisateur
            .putUtilisateur(this.data.collaborateur)
            .pipe(
              mergeMap(() => {
                return this.licenceService.putLicence(
                  this.data.collaborateur.licence.id,
                  diffJours,
                )
              }),
              takeUntil(this.destroyed),
            )
            .subscribe((res) => {
              this.dialogRef.close(res)
              this.snackbar.open("L'utilisateur a bien été modifié !")
            })
        } else {
          this.serviceUtilisateur
            .putUtilisateur(this.data.collaborateur)
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
              this.dialogRef.close(res)
              this.snackbar.open("L'utilisateur a bien été modifié !")
            })
        }
      } else {
        this.serviceUtilisateur
          .putUtilisateur(this.data.collaborateur)
          .pipe(takeUntil(this.destroyed))
          .subscribe((res) => {
            this.dialogRef.close(res)
            this.snackbar.open("L'utilisateur a bien été modifié !")
          })
      }
    } else {
      this.snackbar.open(
        'Il y a une erreur dans le formulaire, verifiez que tous les champs soient correctes.',
      )
    }
  }

  onCancel(): void {
    this.dialogRef.close()
  }

  mdpOublieCollaborateur(): void {
    const titleCasePipe = new TitleCasePipe()
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '50%',
      data: {
        text:
          'Êtes-vous sur de vouloir réinitialiser le mot de passe de ' +
          titleCasePipe.transform(this.prenom.value) +
          ' ' +
          titleCasePipe.transform(this.nom.value) +
          ' ?',
      },
    })
    dialogRef
      .afterClosed()
      .pipe(
        mergeMap((res) => {
          return res
            ? this.serviceUtilisateur.forgotPassword(this.data.collaborateur)
            : of(undefined)
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((res) => {
        if (res) {
          this.snackbar.open(
            "Le mot de passe de l'utilisateur a bien été réinitialisé et envoyé par email !",
          )
        }
      })
  }

  reverseActiveCollaborateur(): void {
    if (this.data.collaborateur.id != this.currentUser!.id) {
      if (this.data.collaborateur.actif) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          width: '50%',
          data: {text: "Désactiver l'utilisateur ?"},
        })
        dialogRef
          .afterClosed()
          .pipe(
            mergeMap((res) => {
              return res
                ? this.serviceUtilisateur.patchUtilisateur(
                  this.data.collaborateur,
                )
                : of(undefined)
            }),
            takeUntil(this.destroyed),
          )
          .subscribe((res) => {
            if (res) {
              const indexItem = this.data.dataSource!.data.findIndex(
                (item) => item.id === this.data.collaborateur.id,
              )
              this.data.dataSource!.data[indexItem].actif = res.actif
              this.data.dataSource!._updateChangeSubscription()
              this.snackbar.open("l'utilisateur a été désactivé !")
            }
          })
      } else {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          width: '50%',
          data: {text: "Activer l'utilisateur ?"},
        })
        dialogRef
          .afterClosed()
          .pipe(
            mergeMap((res) => {
              return res
                ? this.serviceUtilisateur.patchUtilisateur(
                  this.data.collaborateur,
                )
                : of(undefined)
            }),
            takeUntil(this.destroyed),
          )
          .subscribe((res) => {
            if (res) {
              const indexItem = this.data.dataSource!.data.findIndex(
                (item) => item.id === this.data.collaborateur.id,
              )
              this.data.dataSource!.data[indexItem].actif = res.actif
              this.data.dataSource!._updateChangeSubscription()
              this.snackbar.open("l'utilisateur a été réactivé !")
            }
          })
      }
    } else {
      this.snackbar.open(
        'Vous ne pouvez pas activer ou désactiver cet utilisateur.',
      )
    }
  }

  deleteCollaborateur(): void {
    if (this.data.collaborateur.id != this.currentUser!.id) {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        width: '50%',
        data: {text: "Supprimer l'utilisateur (action irreversible) ?"},
      })
      dialogRef
        .afterClosed()
        .pipe(
          mergeMap((res) => {
            return res
              ? this.serviceUtilisateur.delete(this.data.collaborateur)
              : of(undefined)
          }),
          takeUntil(this.destroyed),
        )
        .subscribe((res) => {
          if (res) {
            const indexItem = this.data.dataSource!.data.findIndex(
              (item) => item.id === this.data.collaborateur.id,
            )
            this.data.dataSource!.data.splice(indexItem, 1)
            this.data.dataSource!._updateChangeSubscription()
            this.snackbar.open("L'utilisateur a bien été supprimé !")
          }
        })
    } else {
      this.snackbar.open('Vous ne pouvez pas vous supprimer cet utilisateur.')
    }
  }
}
