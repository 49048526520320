import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {Module} from 'src/app/shared/models/module.models';
import {Commentaire} from 'src/app/shared/models/commentaire.models';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  private url: string = '/module/';

  constructor(
    private http: HttpClient,
  ) {
  }

  public getModuleById(id: number): Observable<Module> {
    return this.http.get<Module>(`${environment.apiUrl}${this.url}${id}`);
  }

  public getModuleScore(id: number): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}${this.url}score/${id}`);
  }

  public getModuleTotalScore(id: number): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}${this.url}totalScore/${id}`);
  }

  public setCommentaire(id: number, commentaire: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}${this.url}commentaire`, {
      dateCreation: new Date(),
      moduleId: id,
      texte: commentaire,
    } as Commentaire);
  }
}
