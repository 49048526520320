<mat-grid-list *ngIf="currentUser != null" cols="12" rowHeight="75pt">
  <mat-grid-tile class="logo-grid" colspan="4">
    <img class="logo" src="/assets/Logo/Logo-SO.png"/>
  </mat-grid-tile>
  <mat-grid-tile class="user-info" colspan="4">
    <div class="col-10" style="padding-left: 1rem">
      <p id="nom">{{ currentUser.prenom }} {{ currentUser.nom }}</p>
      <p id="entreprise">
        {{ currentUser.fonction }} - {{ currentUser.role }} <br/>
        {{ currentUser.ville }} - {{ currentUser.societe }}
      </p>
    </div>
    <div class="col-2" style="text-align: center">
      <button (click)="logout()" mat-button>
        <mat-icon> exit_to_app</mat-icon>
      </button>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="4" id="rdv">
    <div class="column">
      <div *ngIf="info && info.message.length > 0">
        <p class="info" style="margin: 0">
          {{ info.message }}<br/>
          <a *ngIf="info.link" [class.teams]="info.meetingApp == meetingAppEnum.TEAMS" [class.zoom]="info.meetingApp == meetingAppEnum.ZOOM" [href]="info.link"
             target="_blank">
            <img
              *ngIf="info.meetingApp == meetingAppEnum.ZOOM"
              src="/assets/Logo/zoom.svg"
            /><img
            *ngIf="info.meetingApp == meetingAppEnum.TEAMS"
            src="/assets/Logo/teams.svg"
          />
            | Rejoindre la réunion
          </a>
        </p>
      </div>
      <div *ngIf="!info">
        <p class="info" style="margin: 0">Aucune information pour l'instant</p>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
