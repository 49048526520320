import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoachService {

  constructor(
    private http: HttpClient
  ) {
  }

  getCoach(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Utilisateur/Coach`)
  }

  getCoachById(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Utilisateur/Coach/${id}`);
  }

  putCoach(pCoachId: number, pUserId: number): Observable<any> {
    return this.http.put(`${environment.apiUrl}/Utilisateur/assign/${pCoachId}/${pUserId}`, null);
  }
}
