import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import {Produit} from 'src/app/shared/models/produits.models';

@Injectable({
  providedIn: 'root'
})
export class ProduitService {

  constructor(private http: HttpClient) {
  }

  getProduit(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Produit`)
  }

  getProduitFiltred(pFilter: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Produit/filter/${pFilter}`)
  }

  getProduitByClient(pId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Produit/forClient/${pId}`);
  }

  postProduit(produit: Produit): Observable<any> {
    return this.http.post(`${environment.apiUrl}/produit`, produit);
  }

  postProduitLivret(produit: Produit, formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/produit/livret/${produit.id}`, formData);
  }

  putProduitLivret(produit: Produit, formData: FormData): Observable<any> {
    return this.http.put(`${environment.apiUrl}/produit/livret/${produit.id}`, formData);
  }

  deleteProduit(produit: Produit): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/produit/${produit.id}`);
  }

  deleteProduitLivret(produit: Produit): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/produit/livret/${produit.id}`);
  }

  reverseActiveProduit(produit: Produit): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/Produit/reverseActive/${produit.id}`, null);
  }
}
