import {Component, Inject} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs';

import {ClientService} from 'src/app/core/services/webservices/client.service';
import {DestroyedService} from 'src/app/core/services/commons/destroyed.service';

import {Produit} from 'src/app/shared/models/produits.models';
import {Client} from 'src/app/shared/models/client.models';

@Component({
  selector: 'app-modifier-client',
  templateUrl: './modifier-client.component.html',
  styleUrls: ['./modifier-client.component.scss']
})
export class ModifierClientComponent extends DestroyedService {

  produits: Array<Produit> = [];
  control: FormGroup;
  nom: AbstractControl;
  adresse: AbstractControl;
  ville: AbstractControl;
  codePostal: AbstractControl;

  constructor(
    public dialogRef: MatDialogRef<ModifierClientComponent>,
    private serviceClient: ClientService,
    fb: FormBuilder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { client: Client },
  ) {
    super();
    // Init form
    this.control = fb.group({
      nom: [this.data.client.nom],
      adresse: [this.data.client.adresse],
      ville: [this.data.client.ville],
      codePostal: [
        this.data.client.codePostal,
        Validators.compose([
          Validators.minLength(5),
          Validators.pattern('[0-9]*'),
        ]),
      ],
    });
    this.nom = this.control.controls['nom'];
    this.adresse = this.control.controls['adresse'];
    this.ville = this.control.controls['ville'];
    this.codePostal = this.control.controls['codePostal'];
  }

  onSubmit() {
    if (this.control.valid) {
      this.data.client.nom =
        this.nom.value.charAt(0).toUpperCase() +
        this.nom.value.substring(1).toLowerCase();
      this.data.client.adresse =
        this.adresse.value.charAt(0).toUpperCase() +
        this.adresse.value.substring(1).toLowerCase();
      this.data.client.ville =
        this.ville.value.charAt(0).toUpperCase() +
        this.ville.value.substring(1).toLowerCase();
      this.data.client.codePostal = this.codePostal.value;
      this.serviceClient.putClient(this.data.client).pipe(
        takeUntil(this.destroyed)
      )
        .subscribe((res) => {
          this.dialogRef.close(res);
          this.snackBar.open('Le client a boen été modifié !');
        });
    } else {
      this.snackBar.open(
        'Il y a une erreur dans le formulaire, verifiez que tous les champs soient correctes.',
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
